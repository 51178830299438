import React, { useEffect } from 'react'
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";


import Home from '../components/home/Home';
import Landing from '../components/Landing';
import Plain from '../components/Plain';
import Login from '../components/auth/Login';
import Member from '../components/home/Member';
import About from '../components/home/About';
import Contact from '../components/home/Contact';
import Investment from '../components/investment/Investment.js';
import News from '../components/news/News';
import Profile from '../components/user/Profile';
import Dashboard from '../components/Dashboard';
import NewsAdd from '../components/dashboard/news/Add';
import NewsContext from '../context/NewsContext';
import HelperContext from '../context/HelperContext.js';
import { useAuthState } from '../context/AuthContext';
import NewsList from '../components/dashboard/news/List';
import NewsEdit from '../components/dashboard/news/Edit';
import { ACCESS_LEVELS } from '../config/Utilities';
import RequireAuth from './RequireAuth.js'
import ShowNews from '../components/news/Show.js';
import MemberList from '../components/dashboard/member/List.js';
import MemberAdd from '../components/dashboard/member/Add.js';
import MemberEdit from '../components/dashboard/member/Edit.js';
import MetaList from '../components/dashboard/meta/List.js';
import MetaAdd from '../components/dashboard/meta/Add.js';
import MetaEdit from '../components/dashboard/meta/Edit.js';
import InvestmentList from '../components/dashboard/investment/List.js';
import Survey from '../components/home/Survey.js';
import SurveyList from '../components/dashboard/survey/List.js';
import InvestmentAdd from '../components/dashboard/investment/Add.js';
import InvestmentEdit from '../components/dashboard/investment/Edit.js';
import ShowInvestment from '../components/investment/Show.js';
import UserList from '../components/dashboard/user/List.js';
import CategoryList from '../components/dashboard/news/Category.js';


function Router() {

    const {state, dispatch} = useAuthState();
    const navigate = useNavigate();

    useEffect(() => {
        if(JSON.parse(localStorage.getItem("token")) !== undefined){
            dispatch({type: "active"});
        }else{
            navigate("/login", { replace: true });
        }
    },[dispatch]);

    return( 
        <Routes>

            <Route path="/" element={<NewsContext><Landing /></NewsContext>}>
                <Route path="/" element={<Home />} />
                <Route path="member" element={<Member />} />
                <Route path="about" element={<About />} />
                <Route path="contact" element={<Contact />} />
                <Route path="investment" element={<Investment />} />
                <Route path="investment/:id" element={<ShowInvestment />} />

                <Route path="news" element={<News />} />
                <Route path="news/:id" element={<ShowNews />} />

                <Route path='survey' element={<Survey />} />
                
            </Route>

            <Route path="/" element={<Plain />}>
                <Route path="login" element={<Login />} />
                
            </Route>

            <Route path="/h" element={ <HelperContext><Dashboard /></HelperContext>}>
                <Route element={ <RequireAuth allowedRoles={[ACCESS_LEVELS.Administrator, ACCESS_LEVELS.Moderator, ACCESS_LEVELS.Publisher, ACCESS_LEVELS.User]} /> } >
                    <Route path="profile" element={<Profile />} />
                </Route>

                <Route element={ <RequireAuth allowedRoles={[ACCESS_LEVELS.Administrator, ACCESS_LEVELS.Moderator, ACCESS_LEVELS.Publisher]} /> } >
                    <Route path="news" element={ <NewsContext><NewsList /></NewsContext> } /> 
                    <Route path="news/add" element={ <NewsContext><NewsAdd /></NewsContext> } /> 
                    <Route path="news/edit/:id" element={ <NewsContext><NewsEdit /></NewsContext> } /> 
                </Route>

                <Route element={ <RequireAuth allowedRoles={[ACCESS_LEVELS.Administrator, ACCESS_LEVELS.Moderator]} /> } >
                    <Route path='member' element={<MemberList />} />
                    <Route path="member/add" element={ <MemberAdd /> } /> 
                    <Route path="member/edit/:id" element={ <MemberEdit />} /> 

                    <Route path='meta' element={<MetaList />} />
                    <Route path="meta/add" element={ <MetaAdd /> } /> 
                    <Route path="meta/edit/:id" element={ <MetaEdit />} /> 

                    <Route path='investment' element={<InvestmentList />} />
                    <Route path='investment/add' element={<InvestmentAdd />} />
                    <Route path='investment/edit/:id' element={<InvestmentEdit />} />

                    <Route path='survey' element={<SurveyList />} />
                    <Route path='category' element={<CategoryList />} />
                </Route>

                <Route element={ <RequireAuth allowedRoles={[ACCESS_LEVELS.Administrator]} /> } >
                    <Route path='user' element={<UserList />} />
                </Route>
                
            </Route>
          
            
                         
        </Routes>
    );
}

export default Router;
