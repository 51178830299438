import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { getNews, getComments, saveComment } from '../../services/data.service'
import {decode} from 'html-entities';
import parse from "html-react-parser";
//import './News.css';
import { Image } from 'primereact/image';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import moment from 'moment'
import { NEWS_IMAGE_URL } from '../../config/Utilities';
import { FiCornerDownLeft } from "react-icons/fi";
import MetaTags from '../../config/MetaTag';
//import { FacebookShareButton, FacebookIcon } from 'react-share'
//import MetaConfig from '../../utilities/MetaConfig';
import { FacebookShareButton, FacebookIcon, XIcon, TwitterShareButton } from "react-share";


export default function ShowNews() {
  
    const { id } = useParams();
    const [news, setNews] = useState({});
    const [comments, setComments] = useState([])
    const [showReply, setShowReply] = useState({
        isOpen: false,
        parentComment: null
    })
    const formik = useFormik({
        initialValues: {
            name: '',
            description: ''
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = 'Нэрээ оруулна уу.';
            }

            if (!data.description) {
                errors.description = 'Дэлгэрэнгүй тайлбар оруулна уу.';
            }

            return errors;
        },
        onSubmit: (data) => { //data

            const formData = new FormData();
            formData.append("description", data.description);
            formData.append("name", data.name);
            formData.append("parentId", showReply.parentComment);

            saveComment(id,formData)
            .then((res)=>{
                if(res.success){
                    toast.success(res.message);
                    formik.resetForm();
                    setComments([...comments, res.comment])
                }else{
                    toast.error(res.message);
                }
            });
        }
    });


    useEffect(() => {
       
        getNews(id).then(r => setNews(r.data));
        getComments(id).then(r => setComments(r.data))
    },[]);


    return (
        <>
            <MetaTags 
                title={news?.title}
                description={news?.summary}
                image={NEWS_IMAGE_URL+news?.image_source}
                name={"AuthorX"}
            />
            <section className="hero-4 pb-5 pt-8 pt-lg-6 pb-sm-4">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Эхлэл</a></li>
                                    <li className="breadcrumb-item"><a href="/news">Мэдээ, мэдээлэл</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{news?.title ?? ""}</li>
                                </ol>
                            </nav>

                            <div className="mt-4">
                                <a href="#">
                                    <span className="badge badge-soft-orange mb-1">{news?.category?.title ?? ""}</span>
                                </a>
                            </div>
                            <h1 className="hero-title mt-0">{news?.title ?? ""}</h1>
                        </div>
                    </div>

                
                    <div className="row mt-4 align-items-center">
                        <div className="col-auto">
                            <div className="d-flex align-items-center">
                                <img className="me-2 avatar avatar-sm rounded-circle avatar-border" 
                                src={news.user?.image_source != null ? NEWS_IMAGE_URL+news.user?.image_source : "/assets/images/avatars/img-4.jpg"}
                                 alt="" />
                                
                                <div>
                                    <h5 className="m-0"><a href="">{news.user?.first_name + " " +news.user?.last_name }</a></h5>
                                    <p className="text-muted mb-0 fs-13">{ moment(news?.created_at).format("yyyy-MM-DD")} · 3 min read</p>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="text-md-end">
                                <ul className="list-inline mb-0">
                                    <li className="list-inline-item text-muted align-middle me-2 text-uppercase fs-13 fw-medium">Share:</li>
                                    <li className="list-inline-item me-2 align-middle">
                                        <FacebookShareButton url={window.location.href}>
                                            <FacebookIcon size={32} round={true} />
                                        </FacebookShareButton>
                                    </li>
                                    <li className="list-inline-item me-2 align-middle">
                                        <TwitterShareButton url={window.location.href}>
                                            <XIcon size={32} round={true} />
                                        </TwitterShareButton>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                
                </div>
            </section>
            <section className="position-relative pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            
                            <blockquote className="blockquote p-4 my-4 bg-light">
                                <p className="">
                                    {news?.summary}
                                </p>
                                {/* <footer className="blockquote-footer mt-0">
                                    <small className="fs-13">Christian Hall</small>
                                </footer> */}
                            </blockquote>

                            <div className='row mb-4'>
                                <div className='col-md-12'>
                                    {parse(decode(news?.description))}
                                </div>
                            </div>


                            <div data-toggle="image-gallery" data-delegate="a" data-type="image" data-enable-gallery="true" className="mt-4">
                                <div className="row">
                                   {
                                        news?.news_images?.map((m) => (
                                            <div className="col-md-3">
                                                <a href={NEWS_IMAGE_URL+m.image_source}
                                                    data-title="Office Desks">
                                                    <div className="card shadow rounded-sm">
                                                        <div className="card-body p-1">
                                                            <img src={NEWS_IMAGE_URL+m.image_source} alt="" className="img-fluid rounded-sm" />
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        ))
                                   }

                                </div>
                            </div>

                           
                         
                            <ul className="list-inline mb-0 mt-4">
                                <li className="list-inline-item text-muted align-middle me-2 text-uppercase fs-13 fw-medium">Share:</li>
                                <li className="list-inline-item me-2 align-middle">
                                    {/* <a href="#">
                                        <i className="icon-xs icon-dual-primary" data-feather="facebook"></i>
                                    </a> */}

                                    <FacebookShareButton url={window.location.href}>
                                        <FacebookIcon size={32} round={true} />
                                    </FacebookShareButton>
                                </li>
                                <li className="list-inline-item me-2 align-middle">
                                    <TwitterShareButton url={window.location.href}>
                                        <XIcon size={32} round={true} />
                                    </TwitterShareButton>
                                </li>
                            </ul>
                         
                        </div>
                    </div>
                </div>
            </section>
            <section className="position-relative pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div>
                                <h4 className="mb-3">Сэтгэгдлүүд<span className="badge badge-soft-secondary fs-14 align-middle ms-2">{comments.length}</span></h4>
                                
                                {
                                    comments.filter((a) => a.parent_comment == null || a.parent_comment.length < 1).map((c, index) => (

                                        <>
                                            <div className="d-flex align-items-top mt-4" key={"pc"+index}>
                                                <img className="me-2 rounded-sm" src="/assets/images/avatars/img-2.jpg" alt="" height="36" />
                                                <div className="flex-grow-1">
                                                    <h6 className="m-0">{c.name} </h6>
                                                    <p className="text-muted mb-0"><small>{c.created_at}</small></p>

                                                    <p className="my-1">{c.description}</p>

                                                    <div>
                                                        <a onClick={(e) => setShowReply({isOpen: !showReply.isOpen, parentComment: c.uuid})} className="btn btn-sm btn-link text-primary fw-medium p-0">
                                                           <FiCornerDownLeft className="icon-xxs text-dark icon-xs me-1" />Хариулах
                                                        </a>
                                                    </div>
                                                    {
                                                        (showReply.isOpen && showReply.parentComment === c.uuid)
                                                        ?
                                                        <form className="mt-3" onSubmit={formik.handleSubmit}>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Сэтгэгдэл</label>
                                                                        <div className="form-icon position-relative">
                                                                            <i data-feather="message-circle" className="fea icon-sm icons"></i>
                                                                            <textarea id="description"  placeholder="Сэтгэгдэл"  
                                                                            value={formik.values.description}
                                                                            onChange={(e) => {
                                                                                formik.setFieldValue(e.target.name, e.target.value);
                                                                            }}
                                                                            rows="5" name="description" className="form-control ps-5" required=""></textarea>
                                                                        </div>
                                                                    </div>
                                                                </div>
                            
                                                                <div className="col-lg-12 col-md-12 col-12">
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Нэр <span className="text-danger">*</span></label>
                                                                        <div className="form-icon position-relative">
                                                                            <i data-feather="user" className="fea icon-sm icons"></i>
                                                                            <input id="name" name="name" type="text" placeholder="Нэр" 
                                                                            value={formik.values.name}
                                                                            onChange={(e) => {
                                                                                formik.setFieldValue(e.target.name, e.target.value);
                                                                            }}
                                                                            className="form-control ps-5" required="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                        
                                                                <div className="col-md-12">
                                                                    <div className="send d-grid">
                                                                        <button type="submit" className="btn btn-primary">Илгээх</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                        : null
                                                    }


                                                    {
                                                        comments.filter(a => a.parent_comment === c.uuid).sort((aa,bb) => (new Date(aa) - new Date(bb))).map((cc, cindex) => (
                                                            <div className="d-flex align-items-top mt-4" key={cindex}>
                                                                <img className="me-2 rounded-sm" src="/assets/images/avatars/img-6.jpg" alt="" height="36" />
                                                                <div className="flex-grow-1">
                                                                    <h6 className="m-0">{cc.name} </h6>
                                                                    <p className="text-muted mb-0"><small>{cc.created_at}</small></p>

                                                                    <p className="my-1">{cc.description}</p>
                                                                </div> 
                                                            </div> 
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                            <hr className="my-4" />
                                        </>
                                    ))
                                }                             
                                
                            </div>

                            <div className="mt-5 mb-lg-0 mb-5">
                                <div className="card border">
                                    <div className="card-body p-4">
                                        <h4 className="mb-3 mt-0">Сэтгэгдэл үлдээх</h4>

                                        <form onSubmit={formik.handleSubmit}>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <input type="text" className="form-control" id="name" name='name' placeholder="Нэр" value={formik.values.name}
                                                        onChange={(e) => {
                                                            setShowReply({parentComment: null})
                                                            formik.setFieldValue(e.target.name, e.target.value);
                                                        }} />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <textarea type="text" className="form-control" id="description" name='description' placeholder="Сэтгэгдэл" rows="5"
                                                        value={formik.values.description}
                                                        onChange={(e) => {
                                                            setShowReply({parentComment: null})
                                                            formik.setFieldValue(e.target.name, e.target.value);
                                                        }}></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <button type="submit" className="btn btn-secondary">Илгээх</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

        
    );
  
}
