

import React, {Suspense } from 'react'
import Banner from '../layouts/Banner'
import Statistic from '../layouts/Statistic'
import Feedback from '../layouts/Feedback'
import News from '../news/News'

function Home() {
  return (
    <>
         <Banner />
         
         <Suspense fallback={<div>Уншиж байна...</div>}>
         <Statistic />
         </Suspense>

         <Suspense fallback={<div>Уншиж байна...</div>}>
          <News />
         </Suspense>

        

    {/* <Feedback /> */}
    </>
  )
}

export default Home