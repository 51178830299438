import { toast } from 'react-toastify';
import axios from "axios";

const API_URL = "https://naransum.mn/api/";
const TEST_URL = "http://localhost:5000/api/";

export function APIREQUEST() {
    return createInstance(API_URL);
}

const createInstance = (baseURL) => {
    const headers = {
        'Content-Type': 'application/json',
      }

    let api = axios.create({
        baseURL: baseURL,
        timeout: 200000,
        headers,
    });

    api.interceptors.request.use(function (config) {
        const token = JSON.parse(localStorage.getItem('token'));
        config.headers.Authorization =  token ? `Bearer ${token}` : '';
        return config;
    });

    api.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.message !== "Network Error") {
                if (error?.response?.status === 500) {
                    toast.error("Алдаа гарлаа, дахин оролдоно уу!");
                }
                if (error?.response?.status === 401) {
                    toast.error("Шинээр нэвтэрч орно уу!");
                    localStorage.clear();
                    window.location = "/login";
                    
                } 
                if(error?.response?.status === 403){
                    toast.error("Та хандах эрхгүй байна!");
                    window.location = "/h/profile";
                }
                else {
                    throw error;
                }
            } else {
                toast.error("Дахин оролдоно уу!");
                throw error;
            }
        }
    );
    return api;
};
