

import React, { useEffect, useState, useMemo }  from 'react'
import { addUser, getAllUsers, updateUser } from '../../../services/data.service';
import DataTable from "react-data-table-component";
import { ACCESS_LEVEL_LIST, ACCESS_LEVELS, PAGINATION_OPTION } from '../../../config/Utilities';
import { FaEdit, FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert'; // Import
import { toast } from 'react-toastify';
import { Dialog } from 'primereact/dialog';
import { BlockUI } from 'primereact/blockui';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';

export default function UserList() {


    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);


    const [filter, setFilter] = useState('')
 

    const [visibleForm, setVisibleForm] = useState(false);
    const [blocked, setBlocked] = useState(false)

    const formik = useFormik({
        initialValues: {
            type: null,
            id: '',
            fname: '',
            lname: '',
            phone: '',
            alevel: ''
            //isFeatured: false
        },
        validate: (data) => {
            let errors = {};

            if (!data.fname) {
                errors.fname = 'Овог оруулна уу.';
            }

            if (!data.lname) {
                errors.lname = 'Нэр оруулна уу.';
            }

            if (!data.phone) {
                errors.phone = 'Утасны дугаар оруулна уу.';
            }

            if (!data.alevel) {
                errors.alevel = 'Хандах эрх оруулна уу.';
            }

            return errors;
        },
        onSubmit: (data) => { //data

            const formData = new FormData();
            formData.append("fname", data.fname);
            formData.append("lname", data.lname);
            formData.append("phone", data.phone);
            formData.append("alevel", data.alevel);
            
            if(data.type == 1)
            {
                addUser(formData)
                .then((res)=>{
                    if(res.success){
                        toast.success(res.message);
                        fetchList(1)
                    }else{
                        toast.error(res.message);
                    }
                });
            }

            if(data.type == 2)
            {
                
                formData.append("id", data.id);
                updateUser(formData, data.id)
                .then((res)=>{
                    if(res.success){
                        toast.success(res.message);
                    }else{
                        toast.error(res.message);
                    }
                });
            }

            
        }
    });

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

    useEffect(() => {

        fetchList(1)    

    },[])

    const fetchList = async (page, size = perPage) => {
        setLoading(true);
    
        getAllUsers(page, size, filter)
        .then((r) => {
            console.log(r)
            setList(r.data.list);
            setTotalRows(r.data.totalItems);
            setLoading(false);
        })

        
    };

    const handleEdit = (row) => {
        setVisibleForm(true);
        formik.setFieldValue('type', 2) 
        formik.setFieldValue('fname',row.first_name) 
        formik.setFieldValue('lname',row.last_name) 
        formik.setFieldValue('phone',row.phone_number) 
        formik.setFieldValue('alevel',row.access_level) 
        formik.setFieldValue('id',row.uuid) 
    }

    const handleAdd = () => {
        setVisibleForm(true);
        formik.resetForm();
        formik.setFieldValue('type', 1) 
    }

    const buttonStatusTemplate = (row) => {
        return <span className={"badge " + (row.is_active != null && row.is_active == true ? "badge-soft-success" : "badge-soft-danger")}>{row.is_active != null && row.is_active == true ? "Идэвхтэй" : "Хаалттай"}</span>
    }

    const buttonEditTemplate = (row) => {
        return <button type='button' className="btn btn-sm btn-warning" onClick={() => {handleEdit(row)}}><FaEdit /></button>
    }

    const columns = useMemo(() => [
        {
            name: "Овог",
            selector: row => row.first_name,
            sortable: true
        },
        {
            name: "Нэр",
            selector: row => row.last_name,
            sortable: true
        },
        {
            name: "Утасны дугаар",
            selector: row => row.phone_number,
            sortable: true
        },
        {
            name: "Төлөв",
            cell: row => buttonStatusTemplate(row)
        },
        {
            name: "Засварлах",
            cell: row => buttonEditTemplate(row)
        }
      ])

      const handlePageChange = page => {
        fetchList(page);
        setCurrentPage(page);
      };
    
      const handlePerRowsChange = async (newPerPage, page) => {
        fetchList(page, newPerPage);
        setPerPage(newPerPage);
      };

      const handleSearch = () => {

        setCurrentPage(1)
        fetchList(1)
    }


    const handleRole = (e) => {
        formik.setFieldValue("alevel", e.target.value)
    }

  return (
    <>
        <Dialog header="Дэлгэрэнгүй мэдээлэл" visible={visibleForm} style={{ width: '90vw' }} onHide={() => setVisibleForm(false)}>
          <BlockUI blocked={blocked}>
            <div className='row'>
                <div className='col-md-12'> 
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-group">
                            <label>Овог</label>
                            <input id="fname" name="fname" value={formik.values.fname} onChange={(e) => {
                                    formik.setFieldValue('fname', e.target.value);
                                }} className="form-control" />                            
                        
                            {getFormErrorMessage('fname')}
                        </div>
                        <div className="form-group">
                            <label>Нэр</label>
                            <input id="lname" name="lname" value={formik.values.lname} onChange={(e) => {
                                    formik.setFieldValue('lname', e.target.value);
                                }} className="form-control" />                            
                        
                            {getFormErrorMessage('lname')}
                        </div>
                        <div className="form-group">
                            <label>Утасны дугаар</label>
                            <input id="phone" name="phone" value={formik.values.phone} onChange={(e) => {
                                    formik.setFieldValue('phone', e.target.value);
                                }} className="form-control" />                            
                        
                            {getFormErrorMessage('phone')}
                        </div>
                        <div className="form-group">
                            <label>Хандах эрх</label>
                            <select value={formik.values.alevel} onChange={handleRole} className="form-control">
                                <option value="">--Сонгох--</option>
                                {
                                    ACCESS_LEVEL_LIST.map((m) => (
                                        <option value={m.key} key={m.key}>{m.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <button className='btn btn-primary mt-2' type='submit'>Хадгалах</button>
                    </form>
                </div>
            </div>
          </BlockUI>
        </Dialog>
        <section className="position-relative overflow-hidden bg-gradient2 py-3 px-3">
            <div className='container'>
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col">
                                <h4 className="mb-3 mt-0 fs-16">Хэрэглэгчдийн жагсаалт</h4>
                            </div>
                            <div className="col-auto text-end">
                                <a href="#" onClick={() => {handleAdd()}} className="fw-semibold text-primary fs-13">Шинээр бүртгэх <i
                                        className="ms-1 icon-xxs" data-feather="arrow-right"></i></a>
                            </div>
                        </div>

        
                        <div className="row mb-2">
                            <div className="col-md-12">
                                <div className="card mb-0">
                                    <div className="card-body">
                                        <div className='input-group'>
    
                                            <input type='text' className='form-control' placeholder='Хайх утга' name='search' onChange={(e) => {
                                                setFilter(e.target.value);
                                            }}
                                            value={filter || ''} />
                                            <div className='input-group-append'>
                                                <button className='btn btn-primary' onClick={handleSearch} type='button'><FaSearch /></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-2">
                            <div className="col-md-12">
                                <div className="card mb-0">
                                    <div className="card-body">
                                    <DataTable
                                        columns={columns}
                                        data={list}
                                        progressPending={loading}
                                        pagination={[50,100,200]}
                                        paginationServer
                                        paginationTotalRows={totalRows}
                                        paginationDefaultPage={currentPage}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        onChangePage={handlePageChange}
                                        paginationComponentOptions={PAGINATION_OPTION}
                                    />
                        
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                        {/* <div className="row mb-3 mt-4">
                            <div className="col-12">
                                <div className="text-center">
                                    <button className="btn btn-outline-primary btn-sm" type="button">
                                        <span className="spinner-border spinner-border-sm me-1" role="status"
                                            aria-hidden="true"></span>
                                        Load More
                                    </button>
                                </div>
                            </div> 
                        </div> */}
                    </div>
                </div>
            </div>
      </section>
    </>
  )
}
