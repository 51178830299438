import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { updateInvestment, getInvestment, deleteInvestmentImage, updateInvestmentThumbnail, addInvestmentImage } from '../../../services/data.service'
import {decode,encode} from 'html-entities';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { InputNumber  } from 'primereact/inputnumber';
import { InputText } from "primereact/inputtext";
import { Editor } from "primereact/editor";
import { InputTextarea } from 'primereact/inputtextarea';
import { FileUpload } from 'primereact/fileupload';
import { Gallery, Item } from 'react-photoswipe-gallery'
import { OverlayTrigger, Popover} from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { Image } from 'primereact/image';
import { NEWS_IMAGE_URL, QUILL_TOOLBAR } from '../../../config/Utilities';
import { FcCheckmark } from 'react-icons/fc';

function InvestmentEdit() {

    const { id } = useParams();
    const [newsImages, setImages] = useState([]);
    const thumbnailRef = useRef(null)
    const imageRef = useRef(null)
    let navigate = useNavigate();

    const renderHeader = () => {
        return QUILL_TOOLBAR()
    }

    const header = renderHeader();

    const formik = useFormik({
        initialValues: {
            name: '',
            summary: '',
            description: '',
            budget: 0,
            progress: 0,
            begin_date: '',
            imageSource: ''
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = 'Мэдээний гарчиг оруулна уу.';
            }

            if (!data.summary) {
                errors.summary = 'Хураангуй тайлбар оруулна уу.';
            }

            if (!data.description) {
                errors.description = 'Дэлгэрэнгүй тайлбар оруулна уу.';
            }

            return errors;
        },
        onSubmit: (data) => { //data
            console.log(data)
            const formData = new FormData();
            formData.append("description", encode(data.description));
            formData.append("budget", data.budget);
            formData.append("progress", data.progress);
            formData.append("name", data.name);
            formData.append("summary", data.summary);
            formData.append("begin_date", data.begin_date);

            updateInvestment(formData, id)
            .then((res)=>{
                if(res.success){
                    toast.success(res.message);
                }else{
                    toast.error(res.message);
                }
            });
            
        }
    });


    useEffect(() => {

        getInvestment(id).then(r => {
            formik.setFieldValue('name', r.data.name);
            formik.setFieldValue('budget', r.data.budget);
            formik.setFieldValue('progress', r.data.progress);
            formik.setFieldValue('description', decode(r.data.description.replace(/&amp;/g, "&")));
            formik.setFieldValue('summary', r.data.summary);
            formik.setFieldValue('imageSource', r.data.image_source)
            formik.setFieldValue('begin_date', r.data.begin_date);
            setImages(r.data.investment_images)
        });

    }, [])

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };


    const removePhoto = (photo) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                  <div className='custom-ui'>
                    <h2>ЗУРАГ УСТГАХ</h2>
                    <p>ТА ЭНЭ ҮЙЛДЛИЙГ ХИЙХИЙГ ЗӨВШӨӨРЧ БАЙНА УУ?</p>
                    <button className='btn-dismiss' onClick={onClose}>Буцах</button>
                    <button className='btn-confirm'
                      onClick={() => {
                        deleteInvestmentImage(photo)
                        .then((res)=>{
                            if(res.success){
                                setImages(newsImages.filter(item => item.id !== photo));
                                toast.success(res.message);
                            }else{
                                toast.error(res.message);
                            }
                        });
                        onClose();
                      }}
                    >
                      Тийм
                    </button>
                  </div>
                );
            }
        });
        
    }

    const handleThumbnail = (e) => {
        console.log(e.files[0])
        let data = new FormData();
        data.append("thumbnail", e.files[0])

        updateInvestmentThumbnail(id,data)
        .then((res)=>{
            if(res.success){
                formik.setFieldValue('imageSource',res.name)
                toast.success(res.message);
                thumbnailRef.current.clear();
            }else{
                toast.error(res.message);
            }
        });
    }

    const handleImage = (e) => {
        console.log(e.files[0])
        let data = new FormData();
        data.append("image", e.files[0])

        addInvestmentImage(id,data)
        .then((res)=>{
            if(res.success){
                setImages(curr =>[...curr, res.newsImage])
                toast.success(res.message);
                imageRef.current.clear();
            }else{
                toast.error(res.message);
            }
        });
    }

    return(
        <section className='section'>
            <div className='container mt-5'>
            <div className="row">
                <div className="col">
                    <h4 className="mb-3 mt-0 fs-16">Төслийн мэдээлэл шинэчлэх</h4>
                </div>
                <div className="col-auto text-end">
                    <a onClick={() => navigate('/h/investment')} className="fw-semibold text-primary fs-13">Жагсаалт руу буцах <i
                            className="ms-1 icon-xxs" data-feather="arrow-right"></i></a>
                </div>
            </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="form-group p-fluid">
                                <label>Нэр</label>
                                <InputText
                                    id="name"
                                    name="name"
                                    value={formik.values.name}
                                    onChange={(e) => {
                                        formik.setFieldValue('name', e.target.value);
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldInvalid('name') })}
                                />
                                
                            
                                {getFormErrorMessage('name')}
                            </div>
                            <div className="form-group p-fluid">
                                <label>Хураангуй</label>
                                <InputTextarea
                                    id="summary"
                                    name="summary"
                                    rows={4}
                                    cols={30}
                                    className={classNames({ 'p-invalid': isFormFieldInvalid('summary') })}
                                    value={formik.values.summary}
                                    onChange={(e) => {
                                        formik.setFieldValue('summary', e.target.value);
                                    }}
                                />
                                {getFormErrorMessage('summary')}
                            </div>
                            <div className="form-group p-fluid">
                                <label>Эхэлсэн огноо</label>
                                <InputText
                                    id="begin_date"
                                    name="begin_date" 
                                    value={formik.values.begin_date}
                                    onChange={(e) => {
                                        formik.setFieldValue('begin_date', e.target.value);
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldInvalid('begin_date') })}
                                />
                                
                            
                                {getFormErrorMessage('begin_date')}
                            </div>
                            <div className="form-group p-fluid">
                                <label>Төсөв</label>
                                <InputNumber 
                                    id="budget"
                                    name="budget"
                                    value={formik.values.budget}
                                    onValueChange={(e) => {
                                        if(e.target.value != null && e.target.value != undefined)
                                        {
                                            formik.setFieldValue('budget', e.target.value);
                                        }
                                        
                                    }}
                                    minFractionDigits={2}
                                    className={classNames({ 'p-invalid': isFormFieldInvalid('budget') })}
                                />
                            </div>
                            <div className="form-group p-fluid mt-1">
                                <label>Гүйцэтгэлийн хувь</label>
                                <InputNumber 
                                    id="progress"
                                    name="progress"
                                    value={formik.values.progress}
                                    onValueChange={(e) => {
                                        if(e.target.value != null && e.target.value != undefined)
                                        {
                                            formik.setFieldValue('progress', e.target.value);
                                        }
                                    }}
                                    min={0} max={100} minFractionDigits={2}
                                    className={classNames({ 'p-invalid': isFormFieldInvalid('progress') })}
                                />
                            </div>
                            <div className='form-group mt-1'>
                                <label>Дэлгэрэнгүй тайлбар</label>
                                <Editor value={formik.values.description}  headerTemplate={header} className={classNames({ 'p-invalid': isFormFieldInvalid('description') })}
                                onTextChange={(e) => formik.setFieldValue('description',e.htmlValue)} style={{ height: '320px' }} />
                                {getFormErrorMessage('description')}
                            </div>
                                                     
                            <button className='btn btn-primary mt-2' type='submit'><FcCheckmark /> Хадгалах</button>
                        </form>
                    </div>
                </div>
                <div className='row mt-5'>
                    <div className='col-md-3'>
                        <h4>Нүүр зураг</h4><hr/>
                        <Image src={NEWS_IMAGE_URL+formik.values.imageSource} preview alt="Image" width="250" />
                        <FileUpload mode="basic" name="demo[]" ref={thumbnailRef} onSelect={handleThumbnail} className='mt-2'
                        customUpload accept="image/*" maxFileSize={1000000} chooseLabel='Нүүр зураг солих' />
                    </div>
                    <div className='col-md-9'>
                        <h4>Бусад зураг</h4><hr/>
                        <div className='row'>
                            
                            <Gallery className='p-3'>
                        
                            {
                                newsImages.map((i,index) => (
                                    <div className="col-md-3 wrapper" key={index}>
                                        <div className="content">
                                            <div className='single-image'>
                                                <Item
                                                    original={NEWS_IMAGE_URL+i.image_source}
                                                    width="1024"
                                                    height="768"
                                                >
                                                    {({ ref, open }) => (
                                                        <img loading='lazy' ref={ref} onClick={open} className='herd-photos img-fluid' src={"http://localhost:5000/public/uploads/"+i.image_source}  />
                                                    )}
                                                </Item>
                                                
                                            </div>
                                            <OverlayTrigger
                                                trigger="click"
                                                key={index}
                                                placement="bottom"
                                                rootClose 
                                                overlay={
                                                    <Popover id={`popover-positioned-bottom`}>
                                                        <Popover.Body>
                                                            <ul className="list-group custom-popover">
                                                                <li className="list-group-item" key={index+1} onClick={ (e) => removePhoto(i.id) }><span className='mdi mdi-trash-can-outline'></span>Устгах</li>
                                                            </ul>
                                                        </Popover.Body>
                                                    </Popover>
                                                }
                                            >
                                                <button className="edit" type='button' ><i className="mdi mdi-pencil text-white"></i></button>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                ))
                            }
                                <FileUpload mode="basic" name="demo[]" ref={imageRef} onSelect={handleImage} customUpload accept="image/*" maxFileSize={1000000} chooseLabel='Зураг нэмэх' />
                            </Gallery>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
        
        
    );
}

export default InvestmentEdit;
