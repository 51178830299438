let user = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : "";
let token = localStorage.getItem("token")
  ? JSON.parse(localStorage.getItem("token"))
  : "";

export const initialState = {
    isAuthenticated: (token != null && token !== undefined) ? true: false,
    user: null || user,
    token: null || token,
    resultList: []
};

const authReducer = (state, action) => {
    switch(action.type){
        case "login":
            localStorage.setItem('token', JSON.stringify(action.payload.accessToken));
            localStorage.setItem('user', JSON.stringify(action.payload.user));
            return {
              ...state,
              isAuthenticated: true,
              user: action.payload.user,
              token: action.payload.accessToken  
            };
        case "active":
            return{
                ...state,
                isAuthenticated: true,
                user: user,
                token: token,
            };
        case "update":
            localStorage.removeItem('user')
            localStorage.setItem('user', JSON.stringify(action.payload));
            return{
                ...state,
                user: action.payload,
            };
        case "logout":
            localStorage.clear();

            return{
                ...state,
                isAuthenticated: false,
                user: null,
                token: null
            };
        case "search":
            return {
                ...state,
                resultList: action.payload
            };
        default:
            return state;
    }
};

export default authReducer;