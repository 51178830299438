import React, { useEffect, useState } from 'react'
import { addCategory, deleteCategory, fetchCategories, updateCategory } from '../../../services/data.service'
import { FaEdit, FaTrash } from 'react-icons/fa'
import { toast } from 'react-toastify';
import { Dialog } from 'primereact/dialog';
import { BlockUI } from 'primereact/blockui';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { useFormik } from 'formik';

function CategoryList() {


    const [list, setList] = useState([])

    const [visibleForm, setVisibleForm] = useState(false);
    const [blocked, setBlocked] = useState(false)

    const formik = useFormik({
        initialValues: {
            type: null,
            id: '',
            title: '',
            //isFeatured: false
        },
        validate: (data) => {
            let errors = {};

            if (!data.title) {
                errors.title = 'Нэр оруулна уу.';
            }

            return errors;
        },
        onSubmit: (data) => { //data

            const formData = new FormData();
            formData.append("title", data.title);
            
            if(data.type == 1)
            {
                addCategory(formData)
                .then((res)=>{
                    if(res.success){
                        toast.success(res.message);
                    }else{
                        toast.error(res.message);
                    }
                });
            }

            if(data.type == 2)
            {
                
                formData.append("id", data.id);
                updateCategory(formData, data.id)
                .then((res)=>{
                    if(res.success){
                        toast.success(res.message);
                    }else{
                        toast.error(res.message);
                    }
                });
            }

            
        }
    });

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

    useEffect(() => {
        fetchCategories().then((r) => {
            setList(r.data)
        })
    },[])


    const handleEdit = (row) => {
        setVisibleForm(true);
        formik.setFieldValue('type', 2) 
        formik.setFieldValue('title',row.title) 
        formik.setFieldValue('id',row.uuid) 
    }

    const handleDelete = (id) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                  <div className='custom-ui'>
                    <h2>АНГИЛАЛ УСТГАХ</h2>
                    <p>ТА ЭНЭ ҮЙЛДЛИЙГ ХИЙХИЙГ ЗӨВШӨӨРЧ БАЙНА УУ?</p>
                    <button className='btn-dismiss' onClick={onClose}>Буцах</button>
                    <button className='btn-confirm'
                      onClick={() => {
                        deleteCategory(id)
                        .then((res)=>{
                            if(res.success){
                                setList(list.filter(item => item.uuid !== id));
                                toast.success(res.message);
                            }else{
                                toast.error(res.message);
                            }
                        });
                        onClose();
                      }}
                    >
                      Тийм
                    </button>
                  </div>
                );
            }
        });
    }

    const handleAdd = () => {
        setVisibleForm(true);
        formik.resetForm();
        formik.setFieldValue('type', 1) 
    }

  return (
    <>
        <Dialog header="Дэлгэрэнгүй мэдээлэл" visible={visibleForm} style={{ width: '90vw' }} onHide={() => setVisibleForm(false)}>
          <BlockUI blocked={blocked}>
            <div className='row'>
                <div className='col-md-12'> 
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-group">
                            <label>Нэр</label>
                            <input id="title" name="title" value={formik.values.title} onChange={(e) => {
                                    formik.setFieldValue('title', e.target.value);
                                }} className="form-control" />                            
                        
                            {getFormErrorMessage('title')}
                        </div>
                        <button className='btn btn-primary mt-2' type='submit'>Хадгалах</button>
                    </form>
                </div>
            </div>
          </BlockUI>
        </Dialog>
       
        <section className="position-relative overflow-hidden bg-gradient2 py-3 px-3">
            <div className="container">
        
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col">
                                <h4 className="mb-3 mt-0 fs-16">Мэдээний ангиллууд</h4>
                            </div>
                            <div className="col-auto text-end">
                                <a href="#" onClick={handleAdd} className="fw-semibold text-primary fs-13">Шинээр бүртгэх <i
                                        className="ms-1 icon-xxs" data-feather="arrow-right"></i></a>
                            </div>
                        </div>

                
                        {
                            list.map((m) => (
                                <div className="row mb-2" key={m.uuid}>
                                    <div className="col">
                                        <div className="card mb-0">
                                            <div className="card-body">
                                                <div className="row align-items-center justify-content-sm-between">
                                                    <div className="col-lg-6">
                                                        <h6>{m.title}</h6> 
                                                    </div> 
                                                    <div className="col-lg-6">
                                                        <ul className="list-inline text-sm-end mb-0" id="tooltip-container2">
                                                            <li className="list-inline-item pe-3" id="tooltip-container4">
                                                                <button className='btn btn-sm btn-light' onClick={(e) => handleEdit(m)}><FaEdit /></button>
                                                            </li>
                                                            <li className="list-inline-item">
                                                                <button className='btn btn-sm  btn-danger' onClick={(e) => handleDelete(m.uuid)}><FaTrash /></button>
                                                            </li>
                                                        </ul>
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    

                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default CategoryList