import React, { useRef, useState } from 'react'
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { InputText } from "primereact/inputtext";
import { Editor } from "primereact/editor";
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber  } from 'primereact/inputnumber';
import { FileUpload } from 'primereact/fileupload';
import {encode} from 'html-entities';
import { toast } from 'react-toastify';
import { QUILL_TOOLBAR } from '../../../config/Utilities';
import { addInvestment } from '../../../services/data.service';
import { useNavigate} from "react-router-dom";
import { FcCheckmark } from 'react-icons/fc';

function InvestmentAdd() {

    const [newsThumbnail, setThumbnail] = useState(null);
    const [newsImages, setImages] = useState([]);
    let navigate = useNavigate();
    const thumbRef = useRef(null);
    const fileRef = useRef(null);

    

    const renderHeader = () => {
        return QUILL_TOOLBAR()
    }

    const header = renderHeader();

    const formik = useFormik({
        initialValues: {
            name: '',
            summary: '',
            description: '',
            budget: 0,
            progress: 0,
            begin_date: ''
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = 'Мэдээний гарчиг оруулна уу.';
            }

            if (!data.summary) {
                errors.summary = 'Хураангуй тайлбар оруулна уу.';
            }

            if (!data.description) {
                errors.description = 'Дэлгэрэнгүй тайлбар оруулна уу.';
            }

            return errors;
        },
        onSubmit: (data) => { //data

            const formData = new FormData();
            formData.append("description", encode(data.description));
            formData.append("budget", data.budget);
            formData.append("progress", data.progress);
            formData.append("name", data.name);
            formData.append("summary", data.summary);
            formData.append("begin_date", data.begin_date);
            formData.append('thumbnail', newsThumbnail);

            console.log(newsImages)
            newsImages.forEach(file => {
                console.log(file)
                if(file !== undefined)
                {
                    formData.append('images', file);
                }
            });

            addInvestment(formData)
            .then((res)=>{
                if(res.success){
                    toast.success(res.message);
                    formik.resetForm();
                    setThumbnail(null);
                    setImages([]);
                    thumbRef.current.clear();
                    fileRef.current.clear();
                }else{
                    toast.error(res.message);
                }
            });
        }
    });

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

    const handleImages = (e) => {
        const files = e.files;
        console.log(files.length)

        for(let i = 0; i < files.length; i++){
            console.log(files[i])
           
        }

        setImages(curr => [...curr,...files])
    };

    const handleThumbnail = async(e) => {

        setThumbnail(e.files[0]);
        
    };


  return (
    <>
      <section className="position-relative overflow-hidden bg-gradient2 py-3 px-3">
          <div className='container'>
          <div className="row">
                <div className="col">
                    <h4 className="mb-3 mt-0 fs-16">Төслийн мэдээ бүртгэх</h4>
                </div>
                <div className="col-auto text-end">
                    <a onClick={() => navigate(-1)} className="fw-semibold text-primary fs-13">Жагсаалт руу буцах <i
                            className="ms-1 icon-xxs" data-feather="arrow-right"></i></a>
                </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <div className='card'>
                  <div className='card-body'>
                    <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
                        <div className="form-group p-fluid">
                            <label>Нэр</label>
                            <InputText
                                id="name"
                                name="name"
                                value={formik.values.name}
                                onChange={(e) => {
                                    formik.setFieldValue('name', e.target.value);
                                }}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('name') })}
                            />
                            
                        
                            {getFormErrorMessage('name')}
                        </div>
                        <div className="form-group p-fluid">
                            <label>Хураангуй</label>
                            <InputTextarea
                                id="summary"
                                name="summary"
                                rows={4}
                                cols={30}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('summary') })}
                                value={formik.values.summary}
                                onChange={(e) => {
                                    formik.setFieldValue('summary', e.target.value);
                                }}
                            />
                            {getFormErrorMessage('summary')}
                        </div>
                        <div className="form-group p-fluid">
                            <label>Эхэлсэн огноо</label>
                            <InputText
                                id="begin_date"
                                name="begin_date" 
                                value={formik.values.begin_date}
                                onChange={(e) => {
                                    formik.setFieldValue('begin_date', e.target.value);
                                }}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('begin_date') })}
                            />
                            
                        
                            {getFormErrorMessage('begin_date')}
                        </div>
                        <div className="form-group p-fluid">
                            <label>Төсөв</label>
                            <InputNumber 
                                id="budget"
                                name="budget"
                                value={formik.values.budget}
                                onValueChange={(e) => {
                                    if(e.target.value != null && e.target.value != undefined)
                                    {
                                        formik.setFieldValue('budget', e.target.value);
                                    }
                                    
                                }}
                                minFractionDigits={2}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('budget') })}
                            />
                        </div>
                        <div className="form-group p-fluid mt-1">
                            <label>Гүйцэтгэлийн хувь</label>
                            <InputNumber 
                                id="progress"
                                name="progress"
                                value={formik.values.progress}
                                onValueChange={(e) => {
                                    if(e.target.value != null && e.target.value != undefined)
                                    {
                                        formik.setFieldValue('progress', e.target.value);
                                    }
                                }}
                                min={0} max={100} minFractionDigits={2}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('progress') })}
                            />
                        </div>
                        <div className='form-group mt-1'>
                            <label>Дэлгэрэнгүй тайлбар</label>
                            <Editor value={formik.values.description} headerTemplate={header} className={classNames({ 'p-invalid': isFormFieldInvalid('description') })}
                            onTextChange={(e) => formik.setFieldValue('description',e.htmlValue)} style={{ height: '320px' }} />
                            {getFormErrorMessage('description')}
                        </div>
                        
                        <div className='form-group mt-1'>
                            <FileUpload mode="basic" name="demo[]" ref={thumbRef} maxFileSize={10000000} chooseLabel="Нүүр зураг сонгох"  accept="image/*" customUpload onSelect={handleThumbnail}  />
                        </div>
                        <hr/>
                        <div className='form-group mt-1'>
                            <FileUpload name="demos[]"  multiple accept="image/*" 
                                maxFileSize={10000000} customUpload onSelect={handleImages}  ref={fileRef}
                                emptyTemplate={<p className="m-0">Хуулах зурагнуудаа чирээд оруулна уу.</p>} />
                        </div>
                        <button className='btn btn-primary mt-2' type='submit'><FcCheckmark /> Хадгалах</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </section>
    </>
  )
}

export default InvestmentAdd