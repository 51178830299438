


import React from 'react'

function Feedback() {
  return (
    <>
        <section className="section pt-lg-8 pb-lg-7 py-6 position-relative features-4" data-aos="fade-up">
        <div className="container">
            <div className="row testimonials-2">
                <div className="col-lg-3">
                    <div className="row align-items-center">
                        <div className="col">
                            <span className="badge rounded-pill badge-soft-primary px-2 py-1">Feedback</span>
                            <h1 className="display-5 fw-semibold">Trusted by 2500+ customers</h1>
                            <p className="text-muted mx-auto">Some valuables words from our customers.</p>
                        </div>
                    </div>
                    <div className="row mt-3 mb-4 mb-lg-0">
                        <div className="col-auto text-sm-end pt-2 pt-sm-0">
                            <div className="navigations">
                                <button className="btn btn-link text-normal p-0 swiper-custom-prev">
                                    <svg className="bi bi-arrow-left" width="1.75em" height="1.75em" viewBox="0 0 16 16"
                                        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd"
                                            d="M5.854 4.646a.5.5 0 010 .708L3.207 8l2.647 2.646a.5.5 0 01-.708.708l-3-3a.5.5 0 010-.708l3-3a.5.5 0 01.708 0z"
                                            clipRule="evenodd"></path>
                                        <path fillRule="evenodd"
                                            d="M2.5 8a.5.5 0 01.5-.5h10.5a.5.5 0 010 1H3a.5.5 0 01-.5-.5z"
                                            clipRule="evenodd"></path>
                                    </svg>
                                </button>
                                <button className="btn btn-link text-normal p-0 swiper-custom-next">
                                    <svg className="bi bi-arrow-right" width="1.75em" height="1.75em" viewBox="0 0 16 16"
                                        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd"
                                            d="M10.146 4.646a.5.5 0 01.708 0l3 3a.5.5 0 010 .708l-3 3a.5.5 0 01-.708-.708L12.793 8l-2.647-2.646a.5.5 0 010-.708z"
                                            clipRule="evenodd"></path>
                                        <path fillRule="evenodd"
                                            d="M2 8a.5.5 0 01.5-.5H13a.5.5 0 010 1H2.5A.5.5 0 012 8z"
                                            clipRule="evenodd"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-8 offset-lg-1">
                    <div className="slider">
                        <div className="swiper-container" data-toggle="swiper" data-swiper='{"loop":true, "spaceBetween": 24, "autoplay": {"delay": 5000}, "breakpoints": {"576": {"slidesPerView": 1 }, "768": { "slidesPerView": 1 } }, "roundLengths":true, "navigation": {"nextEl": ".swiper-custom-next","prevEl": ".swiper-custom-prev"}}'>
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <div className="card mb-0 border rounded">
                                        <div className="card-body testimonial-body shadow">
                                            <p className="quotation-mark text-muted mb-0">“</p>
                                            <h4 className="fw-normal mb-3 mt-0">
                                                This app is a truly blessing for all professionals! A day to day project management was never easy for me. But with prompt, I can manage more than 100 projects easily.
                                            </h4>
                                            <hr />
                                            <div className="d-flex pt-2 align-items-center">
                                                <img className="me-2 rounded-circle" src="./assets/images/avatars/img-8.jpg" alt="" height="36" />
                                                <div className="flex-grow-1">
                                                    <h6 className="m-0">Cersei Lannister</h6>
                                                    <p className="my-0 text-muted fs-13">Senior Project Manager</p>
                                                </div>
                                                <img className="" src="./assets/images/brands/amazon.svg" alt="" height="32" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="card mb-0 border rounded">
                                        <div className="card-body testimonial-body shadow">
                                            <p className="quotation-mark text-muted mb-0">“</p>
                                            <h4 className="fw-normal mb-3 mt-0">
                                                It is one of the very convenient to use project manager ever! I have tried many project management apps for my daily tasks, but this one is far better than others. Simply loved it!
                                            </h4>
                                            <hr />
                                            <div className="d-flex pt-2 align-items-center">
                                                <img className="me-2 rounded-circle" src="./assets/images/avatars/img-5.jpg" alt="" height="36" />
                                                <div className="flex-grow-1">
                                                    <h6 className="m-0">John Stark</h6>
                                                    <p className="my-0 text-muted fs-13">Engineering Director</p>
                                                </div>
                                                <img className="" src="./assets/images/brands/google.svg" alt="" height="32" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    </section>
    </>
  )
}

export default Feedback