import moment from 'moment'
import { jwtDecode } from "jwt-decode";
import { useAuthState } from '../context/AuthContext';


export const MAX_NEWS_LENGTH = 12;

//export const NEWS_IMAGE_URL = "http://localhost:5000/public/uploads/";
export const NEWS_IMAGE_URL = "https://naransum.mn/public/uploads/";

export const PAGINATION_OPTION = {
	rowsPerPageText: 'Хуудсанд харуулах тоо',
	rangeSeparatorText: '|',
	selectAllRowsItem: true,
	selectAllRowsItemText: 'Бүгд',
};

export const QUILL_TOOLBAR = () => {
    return (
        <>
                <span className="ql-formats">
                    <select className="ql-font"></select>
                    <select className="ql-size"></select>
                </span>
                <span className="ql-formats">
                    <button className="ql-bold"></button>
                    <button className="ql-italic"></button>
                    <button className="ql-underline"></button>
                    <button className="ql-strike"></button>
                </span>
                <span className="ql-formats">
                    <select className="ql-color"></select>
                    <select className="ql-background"></select>
                </span>
                <span className="ql-formats">
                    <button className="ql-script" value="sub"></button>
                    <button className="ql-script" value="super"></button>
                </span>
                <span className="ql-formats">
                    <button className="ql-header" value="1"></button>
                    <button className="ql-header" value="2"></button>
                    <button className="ql-blockquote"></button>
                    <button className="ql-code-block"></button>
                </span>
                <span className="ql-formats">
                    <button className="ql-list" value="ordered"></button>
                    <button className="ql-list" value="bullet"></button>
                    <button className="ql-indent" value="-1"></button>
                    <button className="ql-indent" value="+1"></button>
                </span>
                <span className="ql-formats">
                    <button className="ql-direction" value="rtl"></button>
                    <select className="ql-align"></select>
                </span>
                <span className="ql-formats">
                    <button className="ql-link"></button>
                    <button className="ql-image"></button>
                    <button className="ql-video"></button>
                    <button className="ql-formula"></button>
                </span>
                <span className="ql-formats">
                    <button className="ql-clean"></button>
                </span>
            </>
    )
}

export const REACT_TABLE_STYLE = {
	header: {
		style: {
			minHeight: '56px',
		},
	},
	headRow: {
		style: {
			borderTopStyle: 'solid',
			borderTopWidth: '1px',
		},
	},
	headCells: {
		style: {
			'&:not(:last-of-type)': {
				borderRightStyle: 'solid',
				borderRightWidth: '1px',
			},
		},
	},
	cells: {
		style: {
			'&:not(:last-of-type)': {
				borderRightStyle: 'solid',
				borderRightWidth: '1px',
			},
		},
	},
};

export const ACCESS_LEVELS = {
    "Administrator": "998640", //998640
    "Moderator": "512068",
    "Publisher": "2374",
    "User": "1001"
}

export const ACCESS_LEVEL_LIST = [
    {"name":"Administrator", "key": "998640"}, //998640
    {"name":"Moderator", "key": "512068"},
    {"name":"Publisher", "key": "2374"},
    {"name":"User", "key": "1001"}
]

export const CHECK_OWNER = (id) => {
    const {state} = useAuthState();
    
    const decoded = (state?.token)
        ? jwtDecode(state.token)
        : undefined

    const userId = decoded?.id || ""

    return (userId === id) ? true : false;
}

export const CHECK_PERMISSION = (allowedRoles) => {
    const {state} = useAuthState();

    const decoded = (state?.token)
        ? jwtDecode(state.token)
        : undefined

    //const roles = decoded?.roles || []

    //return (roles.find(role => allowedRoles?.includes(role))) ? true : false;

    const rolesArray = [...allowedRoles];
    return (rolesArray.find(role => role?.includes(decoded?.roles))) ? true : false;
}
