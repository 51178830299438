import React from 'react'
import Navbar from './layouts/Navbar';
import Footer from './layouts/Footer';
import { Outlet } from "react-router-dom";
import Feedback from './layouts/Feedback';

function Landing() {

  

  return (
    <>
       <div>
        <Navbar />
       
        
        <Outlet />
        
        
        <Footer />
       </div>
       
    </>
  )
}

export default Landing