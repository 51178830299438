import { APIREQUEST } from './api';
import { MAX_NEWS_LENGTH } from '../config/Utilities';


/* NEWS */

export const addNews = async(data) => {

  const config = {headers: {"Content-Type": "multipart/form-data"}}

  let list = await APIREQUEST().post("news",data,config);

  return list.data;
}

export const updateNews = async(data, id) => {

  let list = await APIREQUEST().put("news/"+id,data);

  return list.data;
}

export const fetchNews = async(offset, category) => {
 
  let query = `offset=${offset}&limit=${MAX_NEWS_LENGTH}` + 
  (category != null && category.trim().length > 1 ? `&category=${category}` : "")

  let list = await APIREQUEST().get("news?"+query);
  return list.data;
}

export const fetchNewsPagination = async(page, size, search, category) => {
 
  let query = (search !== undefined && search.trim().length > 3 ? `&search=${search}` : '')+
  (category !== undefined && category.trim().length > 3 ? `&category=${category}` : '')


  let list = await APIREQUEST().get(`news/pagination?page=${page}&size=${size}${query}`);
  return list.data;
}


export const getNews = async(id) => {
  let list = await APIREQUEST().get("news/"+id);
  return list.data;
}

export const deleteNews = async(id) => {
  let list = await APIREQUEST().delete("news/"+id);
  return list.data
}


export const deleteNewsImage = async(id) => {
  let list = await APIREQUEST().delete(`news/${id}/image`);
  return list.data;
}

export const addNewsImage = async(id,data) => {
  const config = {headers: {"Content-Type": "multipart/form-data"}}
  let list = await APIREQUEST().post(`news/${id}/image`,data,config);
  return list.data;
}

export const updateNewsThumbnail = async(id,data) => {
  const config = {headers: {"Content-Type": "multipart/form-data"}}
  let list = await APIREQUEST().post(`news/${id}/thumbnail`,data, config);
  return list.data;
}


export const searchNews = async(value) => {
  let list = await APIREQUEST().get("news/search?value="+value);
  return list.data;
}

export const repostNews = async(data) => {

  let list = await APIREQUEST().post("news/repost",JSON.stringify(data));
  return list.data;
}

export const saveComment = async(id,data) => {
  let list = await APIREQUEST().post(`news/${id}/comment`,data);
  return list.data;
}

export const getComments = async(id) => {
  let list = await APIREQUEST().get("news/"+id+"/comment");
  return list.data;
}


/** CATEGORY */

export const fetchCategories = async() => {


  let list = await APIREQUEST().get("category");

  return list.data;
}

export const addCategory = async(data) => {

  let list = await APIREQUEST().post("category",data);

  return list.data;
}

export const updateCategory = async(data, id) => {

  let list = await APIREQUEST().put("category/"+id,data);

  return list.data;
}

export const deleteCategory = async(id) => {
  let list = await APIREQUEST().delete("category/"+id);
  return list.data
}


/* MEMBER */

export const addMember = async(data) => {

  const config = {headers: {"Content-Type": "multipart/form-data"}}

  let list = await APIREQUEST().post("member",data,config);

  return list.data;
}

export const updateMember = async(data, id) => {

  let list = await APIREQUEST().put("member/"+id,data);

  return list.data;
}

export const fetchMember = async(search) => {
 
  let query = (search != null && search.trim().length > 1 ? `&search=${search}` : "")

  let list = await APIREQUEST().get("member?"+query);
  return list.data;
}

export const fetchMemberPagination = async(page, size, search, type) => {
 
  let query = (search !== undefined && search.trim().length > 3 ? `&search=${search}` : '')
   + (type !== undefined && type.trim().length > 1 ? `&type=${type}` : '')


  let list = await APIREQUEST().get(`member/pagination?page=${page}&size=${size}${query}`);
  return list.data;
}

export const getMember = async(id) => {
  let list = await APIREQUEST().get("member/"+id);
  return list.data;
}

export const deleteMember = async(id) => {
  let list = await APIREQUEST().delete("member/"+id);
  return list.data
}


export const updateMemberThumbnail = async(id,data) => { 
  const config = {headers: {"Content-Type": "multipart/form-data"}}
  let list = await APIREQUEST().post(`member/${id}/thumbnail`,data, config);
  return list.data;
}


export const addMemberExperience = async(data, id) => {

  let list = await APIREQUEST().post(`member/${id}/experience`,data);

  return list.data;
}

export const updateMemberExperience = async(data, parent,id) => {

  let list = await APIREQUEST().put(`member/${parent}/experience/${id}`,data);

  return list.data;
}

export const getMemberExperience = async(parent,id) => {
  let list = await APIREQUEST().get(`member/${parent}/experience/${id}`);
  return list.data;
}

export const deleteMemberExperience = async(parent,id) => {
  let list = await APIREQUEST().delete(`member/${parent}/experience/${id}`);
  return list.data
}

export const addMemberEducation = async(data, id) => {

  let list = await APIREQUEST().post(`member/${id}/education`,data);

  return list.data;
}

export const updateMemberEducation = async(data, parent,id) => {

  let list = await APIREQUEST().put(`member/${parent}/education/${id}`,data);

  return list.data;
}

export const getMemberEducation = async(parent,id) => {
  let list = await APIREQUEST().get(`member/${parent}/education/${id}`);
  return list.data;
}

export const deleteMemberEducation = async(parent,id) => {
  let list = await APIREQUEST().delete(`member/${parent}/education/${id}`);
  return list.data
}


export const fetchMemberTypes = async() => {


  let list = await APIREQUEST().get("member/type");

  return list.data;
}

export const addMemberType = async(data) => {

  let list = await APIREQUEST().post("member/type",data);

  return list.data;
}

export const updateMemberType = async(data, id) => {

  let list = await APIREQUEST().put("member/type/"+id,data);

  return list.data;
}

export const deleteMemberType = async(id) => {
  let list = await APIREQUEST().delete("member/type/"+id);
  return list.data
}


/*** meta */

export const addMeta = async(data) => {

  let list = await APIREQUEST().post("meta",data);

  return list.data;
}

export const updateMeta = async(data, id) => {

  let list = await APIREQUEST().put("meta/"+id,data);

  return list.data;
}

export const fetchMetaPagination = async(page, size, search) => {
 
  let query = (search !== undefined && search.trim().length > 3 ? `&search=${search}` : '')


  let list = await APIREQUEST().get(`meta?page=${page}&size=${size}${query}`);
  return list.data;
}

export const getMeta = async(id) => {
  let list = await APIREQUEST().get("meta/"+id);
  return list.data;
}

export const deleteMeta = async(id) => {
  let list = await APIREQUEST().delete("meta/"+id);
  return list.data
}



/** INVESTMENT */

export const addInvestment = async(data) => {

  const config = {headers: {"Content-Type": "multipart/form-data"}}

  let list = await APIREQUEST().post("investment",data,config);

  return list.data;
}


export const updateInvestment = async(data, id) => {

  let list = await APIREQUEST().put("investment/"+id,data);

  return list.data;
}

export const fetchInvestment = async(offset) => {
 
  let query = `offset=${offset}&limit=${MAX_NEWS_LENGTH}`

  let list = await APIREQUEST().get("investment?"+query);
  return list.data;
}

export const fetchInvestmentPagination = async(page, size, search) => {
 
  let query = (search !== undefined && search.trim().length > 3 ? `&search=${search}` : '')


  let list = await APIREQUEST().get(`investment/pagination?page=${page}&size=${size}${query}`);
  return list.data;
}

export const getInvestment = async(id) => {
  let list = await APIREQUEST().get("investment/"+id);
  return list.data;
}

export const deleteInvestment = async(id) => {
  let list = await APIREQUEST().delete("investment/"+id);
  return list.data
}

export const deleteInvestmentImage = async(id) => {
  let list = await APIREQUEST().delete(`investment/${id}/image`);
  return list.data;
}

export const addInvestmentImage = async(id,data) => {
  const config = {headers: {"Content-Type": "multipart/form-data"}}
  let list = await APIREQUEST().post(`investment/${id}/image`,data,config);
  return list.data;
}

export const updateInvestmentThumbnail = async(id,data) => {
  const config = {headers: {"Content-Type": "multipart/form-data"}}
  let list = await APIREQUEST().post(`investment/${id}/thumbnail`,data, config);
  return list.data;
}

export const getInvestmentSummary = async() => {
  let list = await APIREQUEST().get("investment/summary");
  return list.data;
}

/* SURVEY */
export const addSurvey = async(data) => {


  let list = await APIREQUEST().post("member/survey",data);

  return list.data;
}

export const fetchSurveyPagination = async(page, size, search) => {
 
  let query = (search !== undefined && search.trim().length > 3 ? `&search=${search}` : '')


  let list = await APIREQUEST().get(`member/survey?page=${page}&size=${size}${query}`);
  return list.data;
}

export const deleteSurvey = async(id) => {
  let list = await APIREQUEST().delete("member/survey/"+id);
  return list.data
}


/** USER */

export const getAllUsers = async(page, size, search)=>{ 

  let query = search !== undefined && search.trim().length > 4 ? `&search=${search}` : ''

  let list = await APIREQUEST().get(`user?page=${page}&size=${size}${query}`);
  return list.data
}

export const updateInfo = async(data) => {
  let user = JSON.parse(localStorage.getItem("user"));

  let res = await APIREQUEST().put(`user/${user.uuid}`,data);
  return res.data
}

export const updateAvatar = async(data) => {
  let user = JSON.parse(localStorage.getItem("user"));
  const config = {headers: {"Content-Type": "multipart/form-data"}}

  let res = await APIREQUEST().put(`user/${user.uuid}/photo`,data, config);
  return res.data
}

export const updatePassword = async(data) => {
  let user = JSON.parse(localStorage.getItem("user"));

  let res = await APIREQUEST().put(`user/${user.uuid}/password`,data);
  return res.data
}

export const updateUser = async(data,id) => {
  let res = await APIREQUEST().put(`user/admin/${id}`,data);
  return res.data
}

export const resetUserPassword = async(id) => {
  let res = await APIREQUEST().put(`user/admin/${id}/reset`);
  return res.data
}

export const updateUserStatus = async(id) => {
  let res = await APIREQUEST().put(`user/admin/${id}/status`);
  return res.data
}

export const addUser = async(data) => {

  let list = await APIREQUEST().post("user/admin/user",data);

  return list.data;
}

export const getInfo = async(id)=>{ 

  let list = await APIREQUEST().get(`user/${id}`);
  return list.data
}
