

import React from 'react'

function Footer() {
  return (
    <>
        <section className="mt-5 py-3 marketing-6 bg-gradient3 position-relative">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <a className="navbar-brand me-lg-4 mb-4 me-auto" href="#">
                            <img src="/assets/images/logo1.png" height="30" className="d-inline-block align-top" alt="" />
                        </a>
                        <p className="text-muted w-75">Сүхбаатар аймгийн Наран сумын нутгийн зөвлөл</p>
                    </div>
                    <div className="col-sm-auto">
                        <div className="ps-md-5">
                            <h6 className="mb-4 mt-5 mt-sm-2 fs-14 fw-semibold text-uppercase">Бүтэц</h6>
                            <ul className="list-unstyled">
                                <li className="my-3"><a href="#" className="text-muted">Бидний тухай</a></li>
                                <li className="my-3"><a href="#" className="text-muted">Гишүүд</a></li>
                                <li className="my-3"><a href="#" className="text-muted">Шилэн данс</a></li>
                                <li className="my-3"><a href="#" className="text-muted">Холбоо барих</a></li>
                            </ul>
                        </div>
                    </div>
                    {/* <div className="col-sm-auto">
                        <div className="ps-md-5">
                            <h6 className="mb-4 mt-5 mt-sm-2 fs-14 fw-semibold text-uppercase">Knowledge Base</h6>
                            <ul className="list-unstyled">
                                <li className="my-3"><a href="#" className="text-muted">Blog</a></li>
                                <li className="my-3"><a href="#" className="text-muted">Help Center</a></li>
                                <li className="my-3"><a href="#" className="text-muted">Sales Tools catalog</a></li>
                                <li className="my-3"><a href="#" className="text-muted">API</a></li>
                            </ul>
                        </div>
                    </div> */}
                    <div className="col-sm-auto">
                        <div className="ps-md-5">
                            <h6 className="mb-4 mt-5 mt-sm-2 fs-14 fw-semibold text-uppercase">Бусад</h6>
                            <ul className="list-unstyled">
                                <li className="my-3"><a href="#" className="text-muted">Дүрэм</a></li>
                                <li className="my-3"><a href="#" className="text-muted">Үйл ажиллагааны журам</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col text-center">
                        <p className="pb-0 mb-0 text-muted">
                            {new Date().getFullYear()} ©  <a href="https://tumniiekh.mn">Digitech BIP LLC</a>
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <a className="btn btn-soft-primary shadow-none btn-icon btn-back-to-top" href='#'><i className="icon-xs" data-feather="arrow-up"></i></a>
    </>
  )
}

export default Footer