
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { authUser } from "../../services/auth.service";
import { useAuthState } from '../../context/AuthContext';
import { useNavigate } from "react-router-dom";

function Login() {

    const {state,dispatch} = useAuthState();

    const [userInfo,setInfo] = useState({
        phone: "",
        password: ""
    });

    const navigate = useNavigate();

  
    const handleSubmit = (event) => {
        event.preventDefault();
 
        authUser(userInfo.phone, userInfo.password)
        .then((res)=>{
            if(res.success){
                dispatch({type:"login", payload: res});
                navigate("/h/profile", { replace: true });
            }else{
                toast.error(res.message);
            }
        });
    }

    const handleInputChange = (event) => {
        setInfo({ ...userInfo, [event.target.name]: event.target.value });
    }


  return (
    <div className="bg-gradient2 min-vh-100 align-items-center d-flex justify-content-center pb-4 pb-sm-5">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-5 mx-auto">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-md-12 shadow">
                                    <div className="p-xl-5 p-3">
                                        <div className="mx-auto mb-5">
                                            <a href="/" className="d-flex">
                                                <img src="/assets/images/logo1.png" className="align-self-center" alt="logo-img" height="30" />
                                            </a>
                                        </div>

                                        {/* <h6 className="h5 mb-0 mt-3">Welcome back!</h6>
                                        <p className="text-muted mt-1 mb-4">
                                            Enter your email address and password to access admin panel.
                                        </p> */}
                                    
                                        <form  onSubmit={handleSubmit} className="authentication-form">

                                            <div className="mb-3">
                                                <label htmlFor="phone" className="form-label">Утасны дугаар <small>*</small></label>
                                                <input type="text" className="form-control" onChange={handleInputChange}  value={userInfo.username}  id="phone" placeholder="Утасны дугаар" name="phone" />
                                            </div>

                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="password">Нууц үг <small>*</small></label>
                                                {/* <a href="account-forget-password.html" className="float-end text-muted text-unline-dashed ms-1 fs-13">Forgot your password?</a> */}
                                                <input type="password" className="form-control" onChange={handleInputChange}  value={userInfo.username}  id="password" name="password" placeholder="Нууц үг" />
                                            </div>

                                            <div className="mb-0 text-center d-grid">
                                                <button className="btn btn-primary" type="submit">Нэвтрэх</button>
                                            </div>
                                        </form>
                                        

                                        {/* <div className="py-3 text-center"><span
                                                className="fs-13 fw-bold">OR</span>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 text-center">
                                                <a href="" className="btn btn-white w-100">
                                                    <i data-feather="github" className='icon icon-xs me-2'></i>Github
                                                </a>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                               
                            </div>

                        </div> 
                    </div>
                  

                    {/* <div className="row mt-3">
                        <div className="col-12 text-center">
                            <p className="text-muted">Don't have an account? <a href="account-signup.html"
                                    className="text-primary fw-semibold ms-1">Бүртгүүлэх</a></p>
                        </div> 
                    </div> */}
                   

                </div>
            </div>
           
        </div>
       
    </div>
  )
}

export default Login