import React, { useEffect, useState } from 'react'
import { fetchInvestment } from '../../services/data.service'
import { MAX_NEWS_LENGTH, NEWS_IMAGE_URL } from '../../config/Utilities'
import { toast } from 'react-toastify';

function Investment() {

    const [list, setList] = useState([])

    useEffect(() => {
        fetchInvestment(list.length).then((r) => {
            setList(r.data)
        })
    },[])


    const appendList = () => {

        fetchInvestment(list.length)
        .then((res)=>{
            if(res.success){
                setList([...list, ...res.data])

            }else{
                toast.error(res.message);
            }
        });
        
        
    }

  return (
    <>
      <section className="hero-4 pb-5 pt-7 py-sm-7 bg-gradient2">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-7 text-center">
                        <h1 className="hero-title">Хэрэгжүүлж буй төсөл хөтөлбөрүүд</h1>
                        <p className="fs-17 text-muted"></p>
                    </div>
                </div>
            </div>
        </section>

        <section className="overflow-hidden py-5 py-md-6 py-lg-7">
            <div className="container">
            {
                list.map((l,index) => (
                    <div className="row align-items-center" key={l.uuid}>
                        <div className="col-lg-5">
                            <div className="mb-5 mb-lg-0" data-aos="fade-up" data-aos-duration="200">
                                <span className="bg-soft-primary avatar avatar-sm rounded-lg icon icon-with-bg icon-xs text-primary me-3 mb-4">
                                    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                    
                                        <title>{l.name}</title>
                                        <g id="Stockholm-icons-/-Shopping-/-MC" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                                            <path d="M10.8226874,8.36941377 L12.7324324,9.82298668 C13.4112512,8.93113547 14.4592942,8.4 15.6,8.4 C17.5882251,8.4 19.2,10.0117749 19.2,12 C19.2,13.9882251 17.5882251,15.6 15.6,15.6 C14.5814697,15.6 13.6363389,15.1780547 12.9574041,14.4447676 L11.1963369,16.075302 C12.2923051,17.2590082 13.8596186,18 15.6,18 C18.9137085,18 21.6,15.3137085 21.6,12 C21.6,8.6862915 18.9137085,6 15.6,6 C13.6507856,6 11.9186648,6.9294879 10.8226874,8.36941377 Z" id="Combined-Shape" fill="#335EEA" opacity="0.3"></path>
                                            <path d="M8.4,18 C5.0862915,18 2.4,15.3137085 2.4,12 C2.4,8.6862915 5.0862915,6 8.4,6 C11.7137085,6 14.4,8.6862915 14.4,12 C14.4,15.3137085 11.7137085,18 8.4,18 Z" id="Oval-14-Copy" fill="#335EEA"></path>
                                        </g>
                                    </svg>
                                </span>
                                <h1 className="text-dark">{l.name}</h1>
                                <p className="text-muted my-4">{l.summary}</p>
                                <a href={"/investment/"+l.uuid} className="h6 text-primary pt-3">Дэлгэрэнгүй <i className="ms-1 icon-xxs" data-feather="arrow-right"></i></a>
                            </div>
                        </div>

                        <div className="col-lg-6 offset-lg-1">
                            <div className="img-content2 position-relative">
                                <div className="img-up">
                                    <img src={l.image_source != null ? NEWS_IMAGE_URL+l.image_source : "/assets/images/hero/saas2.jpg"} alt="app img"
                                        className="img-fluid d-block rounded" data-aos="fade-left" data-aos-duration="300" />
                                </div>
                            </div>
                        </div>
                        
                    </div>
                ))
            }
           

            <div className="text-center mt-5 pb-md-0">
            {
                list.length >= MAX_NEWS_LENGTH
                ?
                <button className='btn btn-white btn-sm' type='button' onClick={appendList}><i
                    className="icon icon-xxs icon-down-arrow me-2"></i>Өмнөх мэдээлэл</button>
                :
                ""
            }
            </div>
        </div>
    </section>
    </>
  )
}

export default Investment