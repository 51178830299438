import { APIREQUEST } from './api';

const authUser = async(phone, password) => {
    const data = { phone: phone, password: password };
    let res = await APIREQUEST().post(`login`, JSON.stringify(data));
    return res.data 
}

const registerUser = async(data) => {
    let res = await APIREQUEST().post(`register`, JSON.stringify(data));
    return res.data 
}

const logout = () => {
    localStorage.removeItem("token");
}

const saveToken = (data) => {
    localStorage.setItem('token', JSON.stringify(data.accessToken));
    localStorage.setItem('user', JSON.stringify(data.user));
}

const getToken = () => {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken
}

const getUser = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    return user
}

const getOTP = async(data) => {
    let list = await APIREQUEST().post(`auth/otp`,JSON.stringify(data));
    return list.data
}

const verifyOTP = async(data) => {
    let list = await APIREQUEST().post(`auth/otpverify`,JSON.stringify(data));
    return list.data
}

const resetPassword = async(data) => {
    let list = await APIREQUEST().post(`password/reset`,JSON.stringify(data));
    return list.data
}

export {
    authUser,
    logout,
    saveToken,
    getToken,
    getUser,
    getOTP,
    verifyOTP,
    resetPassword,
    registerUser
};
