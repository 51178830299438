import React from 'react'
import { useAuthState } from '../context/AuthContext';
import { Navigate, useLocation, Outlet } from "react-router-dom";
import {jwtDecode} from "jwt-decode";

const RequireAuth = ({allowedRoles}) => {
  
    const {state, dispatch} = useAuthState();
    const location = useLocation();
    const decoded = state?.token
        ? jwtDecode(state.token)
        : undefined

    const roles = []
    roles.push(decoded?.roles)
    
    return (
        
        state?.user
            ? roles.find(role => allowedRoles?.includes(role))
                ? <Outlet/>
                :
                <Navigate to="/h/profile" state={{from:location}} replace />
            : <Navigate to="/login" state={{from:location}} replace />
    )
}


export default RequireAuth;