import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { updateMember, getMember, updateMemberThumbnail, addMemberExperience, deleteMemberExperience, deleteMemberEducation, addMemberEducation, updateMemberEducation, updateMemberExperience, fetchMemberTypes  } from '../../../services/data.service'
import { toast } from 'react-toastify';
import { Dropdown } from 'primereact/dropdown';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import { FileUpload } from 'primereact/fileupload';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { Image } from 'primereact/image';
import { NEWS_IMAGE_URL } from '../../../config/Utilities';
import { FiEdit, FiPlus, FiTrash } from 'react-icons/fi'
import { Dialog } from 'primereact/dialog';
import { BlockUI } from 'primereact/blockui';
import { InputTextarea } from 'primereact/inputtextarea';
import { Message } from 'primereact/message'; 

function MemberEdit() {

  const { id } = useParams();
  let navigate = useNavigate();

  const [educations, setEducation] = useState([])
  const [experiences, setExperience] = useState([])
  const [blocked, setBlocked] = useState(false)
  const [visibleForm, setVisibleForm] = useState(false);
  const [visibleFormt, setVisibleFormt] = useState(false);
  const thumbnailRef = useRef(null)

  const [types, setTypes] = useState([])
    
  useEffect(() => {
      fetchMemberTypes().then((r) => {
          setTypes(r.data)
      })
  },[])

    const formik = useFormik({
        initialValues: {
          fname: '',
          lname: '',
          phone: '',
          address: '',
          position: '',
          description: '',
          imageSource: '',
          type: ''
        },
        validate: (data) => {
            let errors = {};

            if (!data.fname) {
              errors.fname = 'Эцэг/эхийн нэр оруулна ууу.';
            }

            if (!data.lname) {
                errors.lname = 'Өөрийн нэр оруулна уу.';
            }

            if (!data.position) {
                errors.position = 'Албан тушаал оруулна уу.';
            }

            if (!data.phone) {
                errors.phone = 'Утасны дугаар оруулна уу.';
            }

            return errors;
        },
        onSubmit: (data) => { //data
            console.log(data)
            const formData = new FormData();
            formData.append("description", data.description);
            formData.append("fname", data.fname);
            formData.append("lname", data.lname);
            formData.append("position", data.position);
            formData.append("phone", data.phone);
            formData.append("address", data.address);
            formData.append("type", data.type);

            updateMember(formData, id)
            .then((res)=>{
                if(res.success){
                    toast.success(res.message);
                }else{
                    toast.error(res.message);
                }
            });
            
        }
    });

    const formikItem = useFormik({
      initialValues: {
        exp: {},
        edu: {},
        type: null,
        ctype: null
      },
      validate: (data) => {
          let errors = {};

          console.log(data.type)

          /* if(data.type == 1)
          {
            if (!data.exp?.organization) {
              errors.exp.organization = 'Байгууллагын нэр оруулна ууу.';
            }
  
            if (!data.exp.position) {
                errors.exp?.position = 'Албан тушаал оруулна уу.';
            }
          }
          else
          {
            if (!data.edu.location) {
              errors.edu.location = 'Сурсан газрын нэр оруулна ууу.';
            }
  
            if (!data.edu.degree) {
                errors.edu.degree = 'Эзэмшсэн боловсрол, зэргийн нэр оруулна уу.';
            }
          } */
          return errors;
      },
      onSubmit: (data) => { //data
          console.log(data)

          const formData = new FormData();

          if(data.ctype == 1){
            if(data.type == 1)
              {
                formData.append("drange", data.exp.drange);
                formData.append("organization", data.exp.organization);
                formData.append("position", data.exp.position);
                formData.append("description", data.exp.description ?? "");
    
                addMemberExperience(formData, id)
                .then((res)=>{
                    if(res.success){
                        toast.success(res.message);
                        setExperience([...experiences, res.data])
                    }else{
                        toast.error(res.message);
                    }
                });
              }else{
                formData.append("drange", data.edu.drange);
                formData.append("location", data.edu.location);
                formData.append("school", data.edu.school);
                formData.append("degree", data.edu.degree);
                formData.append("description", data.edu.description ?? "");
    
                addMemberEducation(formData, id)
                .then((res)=>{
                    if(res.success){
                        toast.success(res.message);
                        setEducation([...educations, res.data])
                    }else{
                        toast.error(res.message);
                    }
                });
              }
          }
          else{
            if(data.type == 1)
              {
                formData.append("drange", data.exp.drange);
                formData.append("organization", data.exp.organization);
                formData.append("position", data.exp.position);
                formData.append("description", data.exp.description);
    
                updateMemberExperience(formData, id, data.exp.id)
                .then((res)=>{
                    if(res.success){
                        toast.success(res.message);
                        //setExperience([...experiences, res.data])
                    }else{
                        toast.error(res.message);
                    }
                });
              }else{
                formData.append("drange", data.edu.drange);
                formData.append("location", data.edu.location);
                formData.append("school", data.edu.school);
                formData.append("degree", data.edu.degree);
                formData.append("description", data.edu.description);
    
                updateMemberEducation(formData, id, data.edu.id)
                .then((res)=>{
                    if(res.success){
                        toast.success(res.message);
                        //setEducation([...educations, res.data])
                    }else{
                        toast.error(res.message);
                    }
                });
              }
          }

          
      }
  });

  useEffect(() => {

    getMember(id).then(r => {
          formik.setFieldValue('fname', r.data.first_name);
          formik.setFieldValue('lname', r.data.last_name);
          formik.setFieldValue('description', r.data.description);
          formik.setFieldValue('position', r.data.position);
          formik.setFieldValue('phone', r.data.phone_number);
          formik.setFieldValue('imageSource', r.data.image_source)
          formik.setFieldValue('address', r.data.address);
          formik.setFieldValue('type', r.data.type_id);

          setEducation(r.data.member_educations)
          setExperience(r.data.member_experiences)
      });

  }, [])

  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
      return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
  };

  const handleThumbnail = (e) => {
    console.log(e.files[0])
    let data = new FormData();
    data.append("member", e.files[0])

    updateMemberThumbnail(id,data)
    .then((res)=>{
        if(res.success){
            formik.setFieldValue('imageSource',res.name)
            toast.success(res.message);
            thumbnailRef.current.clear();
        }else{
            toast.error(res.message);
        }
    });
  }

  const handleEdit = (cid, type) => {

    formikItem.setFieldValue('type',type)
    formikItem.setFieldValue('ctype',2)
    if(type == 1)
    {
      var temp = experiences.filter((a) => a.uuid == cid)[0]
      formikItem.setFieldValue('exp.organization', temp.organization);
      formikItem.setFieldValue('exp.position', temp.position);
      formikItem.setFieldValue('exp.description', temp.description);
      formikItem.setFieldValue('exp.drange', temp.date_range);
      formikItem.setFieldValue('exp.id', cid);
      setVisibleForm(true)
    }else{
      var temp = educations.filter((a) => a.uuid == cid)[0]
      formikItem.setFieldValue('edu.location', temp.location);
      formikItem.setFieldValue('edu.school', temp.school);
      formikItem.setFieldValue('edu.description', temp.description);
      formikItem.setFieldValue('edu.drange', temp.date_range);
      formikItem.setFieldValue('edu.degree', temp.degree);
      formikItem.setFieldValue('edu.id', cid);
      setVisibleFormt(true)
    }
  }

  const handleDelete = (cid, type) => {
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
              <div className='custom-ui'>
                <h2>НИЙТЛЭЛ УСТГАХ</h2>
                <p>ТА ЭНЭ ҮЙЛДЛИЙГ ХИЙХИЙГ ЗӨВШӨӨРЧ БАЙНА УУ?</p>
                <button className='btn-dismiss' onClick={onClose}>Буцах</button>
                <button className='btn-confirm'
                  onClick={() => {
                    if(type == 1)
                    {
                      deleteMemberExperience(id,cid)
                      .then((res)=>{
                          if(res.success){
                              setExperience(experiences.filter(item => item.uuid !== cid));
                              toast.success(res.message);
                          }else{
                              toast.error(res.message);
                          }
                      });
                    }else{
                      deleteMemberEducation(id,cid)
                      .then((res)=>{
                          if(res.success){
                              setEducation(educations.filter(item => item.uuid !== cid));
                              toast.success(res.message);
                          }else{
                              toast.error(res.message);
                          }
                      });
                    }
                    onClose();
                  }}
                >
                  Тийм
                </button>
              </div>
            );
        }
    });
    
  }

  return (
    <>
      <Dialog header="Туршлагын мэдээлэл" visible={visibleForm} style={{ width: '80vw' }} onHide={() => setVisibleForm(false)}>
          <BlockUI blocked={blocked}>
              <form onSubmit={formikItem.handleSubmit}>
                  <div className='row'>
                      <div className='col-md-6'> 
                          <div className="form-group p-fluid">
                              <label>Ажилласан хугацаа</label>
                              <InputText
                                  id="drange"
                                  name="drange"
                                  value={formikItem.values.exp?.drange}
                                  onChange={(e) => {
                                    formikItem.setFieldValue('exp.drange', e.target.value);
                                  }}
                                  className={classNames({ 'p-invalid': isFormFieldInvalid('exp.drange') })}
                              />
                              {getFormErrorMessage('exp.drange')}
                          </div>
                          <div className="form-group p-fluid">
                              <label>Байгууллага</label>
                              <InputText
                                  id="organization"
                                  name="organization"
                                  value={formikItem.values.exp?.organization}
                                  onChange={(e) => {
                                    formikItem.setFieldValue('exp.organization', e.target.value);
                                  }}
                                  className={classNames({ 'p-invalid': isFormFieldInvalid('exp.organization') })}
                              />
                              {getFormErrorMessage('exp.organization')}
                          </div>
                      </div>
                      <div className='col-md-6'>
                          <div className="form-group p-fluid">
                              <label>Албан тушаал</label>
                              <InputText
                                  id="position"
                                  name="position"
                                  value={formikItem.values.exp?.position}
                                  onChange={(e) => {
                                    formikItem.setFieldValue('exp.position', e.target.value);
                                  }}
                                  className={classNames({ 'p-invalid': isFormFieldInvalid('exp.position') })}
                              />
                              {getFormErrorMessage('exp.position')}
                          </div>
                          <div className='form-group p-fluid'>
                              <label>Нэмэлт мэдээлэл</label>
                              <InputTextarea value={formikItem.values.exp?.description} placeholder="Нэмэлт мэдээлэл"
                              onChange={(e) => {
                                formikItem.setFieldValue('exp.description', e.target.value)
                              }} 
                              rows={3} cols={30} />
                          </div>
                          <button className='btn btn-primary mt-1' type='submit'>Хадгалах</button>
                      </div>
                  </div>
              </form>
          </BlockUI>
      </Dialog>

      <Dialog header="Боловсролын мэдээлэл" visible={visibleFormt} style={{ width: '80vw' }} onHide={() => setVisibleFormt(false)}>
          <BlockUI blocked={blocked}>
              <form onSubmit={formikItem.handleSubmit}>
                  <div className='row'>
                      <div className='col-md-6'> 
                          <div className="form-group p-fluid">
                              <label>Он</label>
                              <InputText
                                  id="drange"
                                  name="drange"
                                  value={formikItem.values.edu?.drange}
                                  onChange={(e) => {
                                    formikItem.setFieldValue('edu.drange', e.target.value);
                                  }}
                                  className={classNames({ 'p-invalid': isFormFieldInvalid('edu.drange') })}
                              />
                              {getFormErrorMessage('edu.drange')}
                          </div>
                          <div className="form-group p-fluid">
                              <label>Сурсан газар</label>
                              <InputText
                                  id="location"
                                  name="location"
                                  value={formikItem.values.edu?.location}
                                  onChange={(e) => {
                                    formikItem.setFieldValue('edu.location', e.target.value);
                                  }}
                                  className={classNames({ 'p-invalid': isFormFieldInvalid('edu.location') })}
                              />
                              {getFormErrorMessage('edu.location')}
                          </div>
                          <div className="form-group p-fluid">
                              <label>Сургууль</label>
                              <InputText
                                  id="school"
                                  name="school"
                                  value={formikItem.values.edu?.school}
                                  onChange={(e) => {
                                    formikItem.setFieldValue('edu.school', e.target.value);
                                  }}
                                  className={classNames({ 'p-invalid': isFormFieldInvalid('edu.school') })}
                              />
                              {getFormErrorMessage('edu.school')}
                          </div>
                      </div>
                      <div className='col-md-6'>
                          <div className="form-group p-fluid">
                              <label>Эзэмшсэн мэргэжил, боловсролын зэрэг</label>
                              <InputText
                                  id="degree"
                                  name="degree"
                                  value={formikItem.values.edu?.degree}
                                  onChange={(e) => {
                                    formikItem.setFieldValue('edu.degree', e.target.value);
                                  }}
                                  className={classNames({ 'p-invalid': isFormFieldInvalid('edu.degree') })}
                              />
                              {getFormErrorMessage('edu.degree')}
                          </div>
                          <div className='form-group p-fluid'>
                              <label>Нэмэлт мэдээлэл</label>
                              <InputTextarea value={formikItem.values.edu?.description} placeholder="Нэмэлт мэдээлэл"
                              onChange={(e) => {
                                formikItem.setFieldValue('edu.description', e.target.value)
                              }} 
                              rows={3} cols={30} />
                          </div>
                          <button className='btn btn-primary mt-1' type='submit'>Хадгалах</button>
                      </div>
                  </div>
              </form>
          </BlockUI>
      </Dialog>

      <section className="position-relative overflow-hidden bg-gradient2 py-3 px-3">
        <div className='container'>
          <div className="row">
                <div className="col">
                    <h4 className="mb-3 mt-0 fs-16">Гишүүний мэдээлэл шинэчлэх</h4>
                </div>
                <div className="col-auto text-end">
                    <a onClick={() => navigate('/h/member')} className="fw-semibold text-primary fs-13">Жагсаалт руу буцах <i
                            className="ms-1 icon-xxs" data-feather="arrow-right"></i></a>
                </div>
            </div>
          <div className='row'>
            <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-12'>
                  <div className='card'>
                  <div className='card-body'>
                  <form onSubmit={formik.handleSubmit}>
                  <div className="form-group p-fluid">
                                <label>Овог</label>
                                <InputText
                                    id="fname"
                                    name="fname"
                                    value={formik.values.fname}
                                    onChange={(e) => {
                                        formik.setFieldValue('fname', e.target.value);
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldInvalid('fname') })}
                                />
                                
                         
                                {getFormErrorMessage('fname')}
                            </div>
                            <div className="form-group p-fluid">
                                <label>Нэр</label>
                                <InputText
                                    id="lname"
                                    name="lname"
                                    value={formik.values.lname}
                                    onChange={(e) => {
                                        formik.setFieldValue('lname', e.target.value);
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldInvalid('lname') })}
                                />
                                
                         
                                {getFormErrorMessage('lname')}
                            </div>
                            <div className="form-group p-fluid">
                                <label>Албан тушаал</label>
                                <InputText
                                    id="position"
                                    name="position"
                                    value={formik.values.position}
                                    onChange={(e) => {
                                        formik.setFieldValue('position', e.target.value);
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldInvalid('position') })}
                                />
                                
                         
                                {getFormErrorMessage('position')}
                            </div>
                            <div className="form-group p-fluid">
                                <label>Утас</label>
                                <InputText
                                    id="phone"
                                    name="phone"
                                    value={formik.values.phone}
                                    onChange={(e) => {
                                        formik.setFieldValue('phone', e.target.value);
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldInvalid('phone') })}
                                />
                                
                         
                                {getFormErrorMessage('phone')}
                            </div>
                            <div className="form-group p-fluid">
                                <label>Хаяг</label>
                                <InputText
                                    id="address"
                                    name="address"
                                    value={formik.values.address}
                                    onChange={(e) => {
                                        formik.setFieldValue('address', e.target.value);
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldInvalid('address') })}
                                />
                                
                         
                                {getFormErrorMessage('address')}
                            </div>
                            <div className="form-group p-fluid">
                                <label>Нэмэлт тайлбар</label>
                                <InputText
                                    id="description"
                                    name="description"
                                    value={formik.values.description}
                                    onChange={(e) => {
                                        formik.setFieldValue('description', e.target.value);
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldInvalid('description') })}
                                />
                                
                         
                                {getFormErrorMessage('description')}
                            </div>
                            <div className='form-group p-fluid'>
                                <label>Гишүүний төрөл</label>
                                <Dropdown value={formik.values.type} 
                                    onChange={(e) => {
                                        formik.setFieldValue('type', e.value);
                                    }}
                                options={types} optionLabel="title" optionValue='uuid'
                                    className={ 'w-full ' + classNames({ 'p-invalid': isFormFieldInvalid('type') })}
                                        placeholder="Гишүүний төрөл"  />

                                    {getFormErrorMessage('type')}
                            </div>
                           
                            <Button className='p-button-success' icon="pi pi-check" label='Хадгалах' type='submit'></Button>
                        </form>
                        
                  </div>
                </div>
                  </div>
                </div>
                <div className='row mt-2'>
                  <div className='col-md-12'>
                      <h4>Зураг</h4><hr/>
                      <Image src={NEWS_IMAGE_URL+formik.values.imageSource} preview alt="Image" width="250" />
                      <FileUpload mode="basic" name="demo[]" ref={thumbnailRef} onSelect={handleThumbnail} className='mt-2'
                      customUpload accept="image/*" maxFileSize={10000000} chooseLabel='Зураг солих' />
                  </div>
                </div>
                
            </div>
            <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='card'>
                      <div className='card-body'>
                          <div className='row'>
                          <div className='col'>
                            <h4>Туршлага</h4>
                          </div>
                          
                          <div className="col-auto text-end">
                            <button className='btn btn-default btn-sm fw-semibold text-dark fs-13' onClick={() => {setVisibleForm(true); formikItem.resetForm(); formikItem.setFieldValue('type', 1); formikItem.setFieldValue('ctype',1)}} type='button'><FiPlus /> Шинээр нэмэх</button>
                          </div>
                          </div>
                          <div className='row'>
                            <div className='col-md-12'>
                            {
                                experiences.map((exp, index) => (
                                  <div className="card mb-0" key={"exp"+index}>
                                    <div className="card-body">
                                        <div className="row align-items-center justify-content-sm-between">
                                            <div className="col-md-8">
                                                <div className="form-check">
                                                    <label className="form-check-label" >
                                                        {exp.date_range + ", " + exp.organization + ", " + exp.position}
                                                    </label>
                                                </div> 
                                            </div> 
                                            <div className="col-md-2">
                                              <button className='btn btn-sm btn-warning btn-round' onClick={() => handleEdit(exp.uuid,1)} type='button'><FiEdit /></button>
                                            </div>
                                            <div className="col-md-2">
                                              <button className='btn btn-sm btn-danger btn-round' onClick={() => handleDelete(exp.uuid,1)} type='button'><FiTrash /></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                ))
                              }
                            </div>
                          </div>
                          
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mt-2'>
                  <div className='col-md-12'>
                    <div className='card'>
                      <div className='card-body'>
                      <div className='row'>
                          <div className='col'>
                            <h4>Боловсрол</h4>
                          </div>
                          
                          <div className="col-auto text-end">
                            <button className='btn btn-default btn-sm fw-semibold text-dark fs-13' onClick={() => {setVisibleFormt(true); formikItem.resetForm(); formikItem.setFieldValue('type', 2); formikItem.setFieldValue('ctype',1)}} type='button'><FiPlus /> Шинээр нэмэх</button>
                          </div>
                          </div>
                          <div className='row'>
                            <div className='col-md-12'>
                            {
                                educations.map((edu, index) => (
                                  <div className="card mb-0" key={"edu"+index}>
                                    <div className="card-body">
                                        <div className="row align-items-center justify-content-sm-between">
                                            <div className="col-md-8">
                                                <div className="form-check">
                                                    <label className="form-check-label" >
                                                        {edu.date_range + ", " + edu.location + ", " + edu.degree}
                                                    </label>
                                                </div> 
                                            </div> 
                                            <div className="col-md-2">
                                              <button className='btn btn-sm btn-warning btn-round' onClick={() => handleEdit(edu.uuid,2)} type='button'><FiEdit /></button>
                                            </div>
                                            <div className="col-md-2">
                                              <button className='btn btn-sm btn-danger btn-round' onClick={() => handleDelete(edu.uuid,2)} type='button'><FiTrash /></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                ))
                              }
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default MemberEdit