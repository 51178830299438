import React, { useEffect, useRef, useState } from 'react'
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { InputText } from "primereact/inputtext";
import { FileUpload } from 'primereact/fileupload';
import { toast } from 'react-toastify';
import { addMember, fetchMemberTypes } from '../../../services/data.service';
import { useNavigate} from "react-router-dom";
import { useParams } from 'react-router-dom';
import { FcCheckmark } from 'react-icons/fc'
import { Dropdown } from 'primereact/dropdown';

function MemberAdd() {

    const [memberThumbnail, setThumbnail] = useState(null);
    let navigate = useNavigate();
    const thumbRef = useRef(null);

    const [types, setTypes] = useState([])
    
    useEffect(() => {
        fetchMemberTypes().then((r) => {
            setTypes(r.data)
        })
    },[])

    const formik = useFormik({
        initialValues: {
            fname: '',
            lname: '',
            phone: '',
            address: '',
            position: '',
            description: '',
            type: ''
            //isFeatured: false
        },
        validate: (data) => {
            let errors = {};

            if (!data.fname) {
                errors.fname = 'Эцэг/эхийн нэр оруулна ууу.';
            }

            if (!data.lname) {
                errors.lname = 'Өөрийн нэр оруулна уу.';
            }

            if (!data.position) {
                errors.position = 'Албан тушаал оруулна уу.';
            }

            if (!data.phone) {
                errors.phone = 'Утасны дугаар оруулна уу.';
            }

            return errors;
        },
        onSubmit: (data) => { //data

            const formData = new FormData();
            formData.append("description", data.description);
            formData.append("position", data.position);
            formData.append("phone", data.phone);
            formData.append("fname", data.fname);
            formData.append("lname", data.lname);
            formData.append("address", data.address);
            formData.append("type", data.type)
            formData.append('member', memberThumbnail);

            addMember(formData)
            .then((res)=>{
                if(res.success){
                    toast.success(res.message);
                    formik.resetForm();
                    setThumbnail(null);
                    thumbRef.current.clear();
                }else{
                    toast.error(res.message);
                }
            });
        }
    });

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };


    const handleThumbnail = async(e) => {
        console.log(e.files[0])
        setThumbnail(e.files[0]);
        console.log(memberThumbnail)
        
    };


  return (
    <>
      <section className="position-relative overflow-hidden bg-gradient2 py-3 px-3">
          <div className='container'>
          <div className="row">
                <div className="col">
                    <h4 className="mb-3 mt-0 fs-16">Гишүүн шинээр бүртгэх</h4>
                </div>
                <div className="col-auto text-end">
                    <a onClick={() => navigate(-1)} className="fw-semibold text-primary fs-13">Жагсаалт руу буцах <i
                            className="ms-1 icon-xxs" data-feather="arrow-right"></i></a>
                </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <div className='card'>
                  <div className='card-body'>
                    <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
                            <div className="form-group p-fluid">
                                <label>Овог</label>
                                <InputText
                                    id="fname"
                                    name="fname"
                                    value={formik.values.fname}
                                    onChange={(e) => {
                                        formik.setFieldValue('fname', e.target.value);
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldInvalid('fname') })}
                                />
                                
                         
                                {getFormErrorMessage('fname')}
                            </div>
                            <div className="form-group p-fluid">
                                <label>Нэр</label>
                                <InputText
                                    id="lname"
                                    name="lname"
                                    value={formik.values.lname}
                                    onChange={(e) => {
                                        formik.setFieldValue('lname', e.target.value);
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldInvalid('lname') })}
                                />
                                
                         
                                {getFormErrorMessage('lname')}
                            </div>
                            <div className="form-group p-fluid">
                                <label>Албан тушаал</label>
                                <InputText
                                    id="position"
                                    name="position"
                                    value={formik.values.position}
                                    onChange={(e) => {
                                        formik.setFieldValue('position', e.target.value);
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldInvalid('position') })}
                                />
                                
                         
                                {getFormErrorMessage('position')}
                            </div>
                            <div className="form-group p-fluid">
                                <label>Утас</label>
                                <InputText
                                    id="phone"
                                    name="phone"
                                    value={formik.values.phone}
                                    onChange={(e) => {
                                        formik.setFieldValue('phone', e.target.value);
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldInvalid('phone') })}
                                />
                                
                         
                                {getFormErrorMessage('phone')}
                            </div>
                            <div className="form-group p-fluid">
                                <label>Хаяг</label>
                                <InputText
                                    id="address"
                                    name="address"
                                    value={formik.values.address}
                                    onChange={(e) => {
                                        formik.setFieldValue('address', e.target.value);
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldInvalid('address') })}
                                />
                                
                         
                                {getFormErrorMessage('address')}
                            </div>
                            <div className="form-group p-fluid">
                                <label>Нэмэлт тайлбар</label>
                                <InputText
                                    id="description"
                                    name="description"
                                    value={formik.values.description}
                                    onChange={(e) => {
                                        formik.setFieldValue('description', e.target.value);
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldInvalid('description') })}
                                />
                                
                         
                                {getFormErrorMessage('description')}
                            </div>
                            <div className='form-group p-fluid'>
                                <label>Гишүүний төрөл</label>
                                <Dropdown value={formik.values.type} 
                                    onChange={(e) => {
                                        formik.setFieldValue('type', e.value);
                                    }}
                                options={types} optionLabel="title" optionValue='uuid'
                                    className={ 'w-full ' + classNames({ 'p-invalid': isFormFieldInvalid('type') })}
                                        placeholder="Гишүүний төрөл"  />

                                    {getFormErrorMessage('type')}
                            </div>
                            <div className='form-group mt-1'>
                                <FileUpload mode="basic" name="demo[]" ref={thumbRef} maxFileSize={10000000} chooseLabel="Нүүр зураг сонгох"  accept="image/*" customUpload onSelect={handleThumbnail}  />
                            </div>
                            <hr/>
                            <button className='btn btn-primary mt-2' type='submit'><FcCheckmark /> Бүртгэх</button>
                            
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </section>
    </>
  )
}

export default MemberAdd