import React, { useEffect } from 'react'
import { BrowserRouter } from "react-router-dom";
import './App.css';
import 'moment/locale/mn'
import { ToastContainer } from 'react-toastify';
import  Router  from "./route/Router";
import AuthContext from "./context/AuthContext"
import { HelmetProvider } from 'react-helmet-async';

function App() {

  useEffect(() => {
    document.title = "Сүхбаатар аймаг- Наран сум"
  }, [])


 /*  useEffect(() => {
    window.fbAsyncInit = () => {
        window.FB.init({
            appId            : '997144345515731',
            autoLogAppEvents : true,
            xfbml            : true,
            version          : 'v11.0'
        });
    };
    (function (d, s, id) {
          var js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) { return; }
          js = d.createElement(s); js.id = id;
          js.src = "https://connect.facebook.net/en_US/sdk.js";
          fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
  }, []); */

  return (
    <HelmetProvider>
      <AuthContext>
        <BrowserRouter>
              {/* {routing} */}
              <ToastContainer />
              <Router />
          
        </BrowserRouter>
      </AuthContext>
    </HelmetProvider>
    
    
  );
}

export default App;
