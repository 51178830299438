import React from 'react'
import { Outlet } from "react-router-dom";
import { useAuthState } from '../context/AuthContext';
import MenuNav from './dashboard/layouts/MenuNav';

function Dashboard() {


    const { state, dispatch } = useAuthState();
  
  return (
    <>
       <div>
        <MenuNav />
       
        <section className="position-relative overflow-hidden bg-gradient2 py-3 px-3">
        <div className='container'>
        <div className="row">
                <div className="col-lg-12">
                    <div className="page-title">
                        <h3 className="mb-0">Сайн байна уу, { state?.user.first_name+ " " + state?.user.last_name } </h3>
                        <p className="mt-1 fw-medium">{state?.user.phone_number}</p>
                    </div>
                </div>
            </div>
            {/* <div className="row mt-2">
               
                <div className="col-lg-5">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <div className="d-flex">
                                        <img src="./assets/images/avatars/img-8.jpg" className="img-fluid avatar-sm rounded-sm me-3" alt="..." />
                                        <div className="flex-grow-1">
                                            <h4 className="mb-1 mt-0 fs-16">Ms. Greeva Navadiya</h4>
                                            <p className="text-muted pb-0 fs-14">Web & Graphic Designer</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto text-end">
                                    <div className="dropdown">
                                        <a className="btn-link text-muted dropdown-toggle arrow-none" href="#" role="button"
                                            id="dropdownMenuLink-1" data-bs-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false">
                                            <i className="icon icon-xs" data-feather="more-horizontal"></i>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-end"
                                            aria-labelledby="dropdownMenuLink-1">
                                            <a className="dropdown-item" href="#"><i className="icon-xxs icon me-2"
                                                    data-feather="edit"></i>Edit</a>
                                            <a className="dropdown-item" href="#"><i className="icon-xxs icon me-2"
                                                    data-feather="refresh-cw"></i>Refresh</a>
                                            <div className="dropdown-divider"></div>
                                            <a className="dropdown-item text-danger" href="#"><i className="icon-xxs icon me-2"
                                                    data-feather="trash-2"></i>Deactivate</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <ul className="list-inline py-3 border-bottom">
                                <li className="list-inline-item mb-sm-0 mb-2 me-sm-2">
                                    <a href="#" className="text-muted fs-14"><i className="icon-xs icon me-1"
                                            data-feather="mail"></i>greeva@coderthemes.com</a>
                                </li>
                                <li className="list-inline-item mb-sm-0 mb-2">
                                    <a href="#" className="text-muted fs-14"><i className="icon-xxs icon me-2"
                                            data-feather="phone"></i>+00 123-456-789</a>
                                </li>
                            </ul>

                            <div className="row align-items-center pt-1">
                                <div className="col-md-6">
                                    <p className="float-end mb-0">85%</p>
                                    <h6 className="fw-medium my-0">Project Completion</h6>
                                    <div className="progress mt-3" style={{height: "6px"}}>
                                        <div className="progress-bar bg-primary" role="progressbar" style={{width: "85%"}}
                                            aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-sm-0 mt-4">
                                    <p className="float-end mb-0">7.5</p>
                                    <h6 className="fw-medium my-0">Overall Rating</h6>
                                    <div className="progress mt-3" style={{height: "6px"}}>
                                        <div className="progress-bar bg-orange" role="progressbar" style={{width: "75%"}}
                                            aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex align-items-center">
                                <div className="avatar-sm icon icon-with-bg icon-xs rounded-sm bg-soft-success me-3">
                                    <i className="icon-dual-success" data-feather="check-circle"></i>
                                </div>
                                <div className="flex-grow-1">
                                    <h3 className="mt-0 mb-0">21</h3>
                                    <p className="text-muted mb-0">Tasks Completed</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex align-items-center">
                                <div className="avatar-sm icon icon-with-bg icon-xs rounded-sm bg-soft-info me-3 flex-shrink-0">
                                    <i className="icon-dual-info" data-feather="edit-3"></i>
                                </div>
                                <div className="flex-grow-1">
                                    <h3 className="mt-0 mb-0">21</h3>
                                    <p className="text-muted mb-0">Tasks Inprogress</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <h4 className="mb-1 mt-0 fs-16">Revenue</h4>
                                </div>
                                <div className="col-auto text-end">
                                    <div className="dropdown">
                                        <a className="btn-link text-muted dropdown-toggle arrow-none" href="#" role="button"
                                            id="dropdownMenuLink-2" data-bs-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false">
                                            <i className="icon icon-xs" data-feather="more-horizontal"></i>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-end"
                                            aria-labelledby="dropdownMenuLink-2">
                                            <a className="dropdown-item" href="#"><i className="icon-xxs icon me-2"
                                                    data-feather="edit"></i>Edit</a>
                                            <a className="dropdown-item" href="#"><i className="icon-xxs icon me-2"
                                                    data-feather="refresh-cw"></i>Refresh</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h1 className="">$2,100.00</h1>
                            <p className="text-muted">Last Week</p>

                            <hr className="mb-1" />
                            <div className="row">
                                <div className="col-6">
                                    <div className="d-flex align-items-center mt-2">
                                        <div className="me-3 flex-shrink-0">
                                            <i className="text-success" data-feather="trending-up"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="mt-0 mb-0">15%</h5>
                                            <p className="text-muted mb-0 fs-13">Prev Week</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="d-flex align-items-center mt-2">
                                        <div className="me-3 flex-shrink-0">
                                            <i className="text-danger" data-feather="trending-down"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="mt-0 mb-0">10%</h5>
                                            <p className="text-muted mb-0 fs-13">Prev Month</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
            </div> */}
        </div>
      </section>
        
        <Outlet />
        
        
        <section className="section py-4 position-relative">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col">
                        <ul className="list-inline list-with-separator mb-0">
                            <li className="list-inline-item me-0"><a href="/about">Бидний тухай</a></li>
                            <li className="list-inline-item me-0"><a href="/policy">Үйл ажиллагаа</a></li>
                            <li className="list-inline-item me-0"><a href="/contact">Холбоо барих</a></li>
                        </ul>
                    </div>
                    <div className="col-md-auto text-md-end mt-2 mt-md-0">
                        <p className="fs-14 mb-0">
                        {new Date().getFullYear()} ©  <a href="https://tumniiekh.mn">Digitech BIP LLC</a>
                            </p>
                    </div>
                </div>
            </div>
        </section>
       </div>
       
    </>
  )
}

export default Dashboard