import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { updateMeta, getMeta } from '../../../services/data.service'
import {decode,encode} from 'html-entities';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { InputText } from "primereact/inputtext";
import { Editor } from "primereact/editor";

import {  QUILL_TOOLBAR } from '../../../config/Utilities';
import { FcCheckmark } from 'react-icons/fc';

function MetaEdit() {

    const { id } = useParams();
    let navigate = useNavigate();

    const renderHeader = () => {
        return QUILL_TOOLBAR()
    }

    const header = renderHeader();

    const formik = useFormik({
        initialValues: {
            name: '',
            key: '',
            description: ''
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = 'Нэр оруулна уу.';
            }

            if (!data.description) {
                errors.description = 'Дэлгэрэнгүй тайлбар оруулна уу.';
            }

            return errors;
        },
        onSubmit: (data) => { //data
            console.log(data)
            const formData = new FormData();
            formData.append("description", encode(data.description));
            formData.append("name", data.name);

            updateMeta(formData, id)
            .then((res)=>{
                if(res.success){
                    toast.success(res.message);
                }else{
                    toast.error(res.message);
                }
            });
            
        }
    });


    useEffect(() => {

        getMeta(id).then(r => {
            formik.setFieldValue('name', r.data.name);
            formik.setFieldValue('key', r.data.key_word);
            formik.setFieldValue('description', decode(r.data.description.replace(/&amp;/g, "&")));

        });

    }, [])

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };


    return(
        <section className='section'>
            <div className='container mt-5'>
            <div className="row">
                <div className="col">
                    <h4 className="mb-3 mt-0 fs-16">Ерөнхий мэдээлэл шинэчлэх</h4>
                </div>
                <div className="col-auto text-end">
                    <a onClick={() => navigate('/h/meta')} className="fw-semibold text-primary fs-13">Жагсаалт руу буцах <i
                            className="ms-1 icon-xxs" data-feather="arrow-right"></i></a>
                </div>
            </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="form-group p-fluid">
                                <label>Нэр</label>
                                <InputText
                                    id="name"
                                    name="name"
                                    value={formik.values.name}
                                    onChange={(e) => {
                                        formik.setFieldValue('name', e.target.value);
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldInvalid('name') })}
                                />
                                
                         
                                {getFormErrorMessage('name')}
                            </div>
                            <div className="form-group p-fluid">
                                <label>Түлхүүр үг</label>
                                <InputText
                                    id="key"
                                    name="key"
                                    value={formik.values.key}
                                    readOnly
                                />
                            
                            </div>
                            <div className='form-group mt-1'>
                                <label>Дэлгэрэнгүй тайлбар</label>
                                <Editor value={formik.values.description}  headerTemplate={header} className={classNames({ 'p-invalid': isFormFieldInvalid('description') })}
                                onTextChange={(e) => formik.setFieldValue('description',e.htmlValue)} style={{ height: '320px' }} />
                                {getFormErrorMessage('description')}
                            </div>
                                                      
                            <button className='btn btn-primary mt-2' type='submit'><FcCheckmark /> Хадгалах</button>
                            
                        </form>
                    </div>
                </div>
            </div>
        </section>
        
        
    );
}

export default MetaEdit;
